.clickeffect {
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    position: relative;
}

.clickeffect:hover {
    transition-duration: 0.1s;
}

.clickeffect:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 8px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 5px 30px white;
}

.clickeffect:active:after {
    box-shadow: 0px 0px 0px 1px #58b07563;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
    border-radius: 8px;
}

.clickeffect:active {
    top: 1px;
}

/* Checkboxes  */
.form-check-input {
    border: 1px solid var(--primary) !important;
    border-radius: 4px !important;
}

.form-check-input:checked[type=checkbox] {
    background-image: url('./../../../public/images/Checkbox.png');
}

.form-control,
input {
    width: 100%;
    padding: 16px 16px 16px 16px;
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--label);
    height: auto;
}

.PhoneInputCountry {
    padding: 16px 16px 16px 16px;
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--label);
    height: auto;
}

.form-control::placeholder {
    color: var(--form-placeholder);
}

.form-control:focus,
input:focus,
.PhoneInputCountry:focus {
    color: #212529;
    background-color: var(--white);
    border-color: var(--primary);
    outline: 0;
}

.form-control:focus,
.form-control:focus-visible,
input:focus-visible,
.PhoneInputCountry:focus-visible {
    background: var(--white) !important;
    border: 1px solid var(--primary) !important;
}

.btn-green {
    padding: 12px 15px;
    width: 100%;  
    background-color: transparent;
    color: var(--textColor);
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    border-radius: var(--borderRadius);
    border: 1px solid var(--borderColor);
}

.btn-green:hover {
    background-color: transparent;
    color: var(--textColorDark);
    border-color: var(--borderColor);
}

.btn-unfill {
    padding: 14px 20px;
    height: auto;
    width: 100%;
    background-color: transparent;
    color: var(--primary);
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    transition: .3s ease;
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid var(--primary);
}

.btn-unfill:hover {
    background-color: var(--primary);
    color: var(--white);
}

.btn-del {
    padding: 14px 30px;
    background: var(--white);
    border: 1.4px solid #F46F61;
    border-radius: 5px;
    color: #F46F61;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.btn-del:hover {
    color: var(--white);
    background: #F46F61;
}

/* Landing page css start here  */
.Banner {
    padding: 20px 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.float {
    animation: float 6s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}

.Banner .bannerryt img.shape1 {
    position: absolute;
    top: 40px;
    left: 40px;
}

.Banner::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-image: url('../../../public/images/rytbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 45%;
    height: 100%;
    z-index: 1;
}

.Banner .bannerryt img.shape2 {
    position: absolute;
    bottom: -80px;
    right: 40px;
    animation: float 3s ease-in-out infinite;
}

.Banner .bannerleft h5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-transform: capitalize;
    color: var(--primary);
    margin-bottom: 18px;
}

.Banner .bannerleft h1 {
    font-weight: 500;
    font-size: 64px;
    line-height: 107.5%;
    text-transform: capitalize;
    color: #01112D;
    margin-bottom: 20px;
}

.landing-wrapper .container,
.PrimaryHeader .container {
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}

.Banner .bannerleft h1 span {
    color: var(--primary);
}

.Banner .bannerleft h1 span {
    color: var(--primary);
}

.Banner .bannerleft form {
    position: relative;
    margin-bottom: 12px;
}

.Banner .bannerleft form .theme-forminput {
    margin: 0;
    display: flex;
    justify-content: flex-start;
}

.Banner .bannerleft form .theme-forminput input {
    width: 100%;
    max-width: 500px;
    border-radius: 5px 0 0 5px;
}

.Banner .bannerleft form .theme-forminput button {
    width: auto;
    padding: 14px 40px;
    border-radius: 0px 5px 5px 0;
}

.Banner .bannerleft p {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #C9C9C9;
    margin-bottom: 60px;
}

.Banner .bannerleft h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: var(--primary);
}

.Banner .container .row {
    align-items: center;
}

.Banner .bannerryt {
    text-align: right;
    position: relative;
}

.Banner .bannerryt {
    text-align: right;
    z-index: 2;
    position: relative;
}

.landingtitlebar {
    text-align: center;
    margin-bottom: 120px;
}

.landingtitlebar h5 {
    font-weight: 300;
    font-size: 20px;
    line-height: 138.5%;
    text-align: center;
    letter-spacing: 0.105em;
    text-transform: uppercase;
    color: var(--primary);
}

.landingtitlebar h3 {
    font-weight: 500;
    font-size: 54px;
    line-height: 138.5%;
    text-align: center;
    text-transform: capitalize;
    color: var(--primary);
    margin: 0 0 12px 0;
}

.landingtitlebar p {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    color: #545456;
}

.Services {
    padding-bottom: 100px;
}

.Services .servicesbox {
    background: var(--white);
    box-shadow: 0px 0px 40.9643px rgba(0, 0, 0, 0.05);
    padding: 70px 40px 50px 40px;
    text-align: center;
    transition: .3s ease;
    cursor: pointer;
    min-height: 478px;
}

.Services .servicesbox img {
    margin-bottom: 30px;
}

.Services .servicesbox h4 {
    font-weight: 500;
    font-size: 28px;
    line-height: 125%;
    color: #2C2762;
    margin-bottom: 18px;
}

.Services .servicesbox h4 span {
    color: var(--primary);
}

.Services .servicesbox p {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    font-feature-settings: 'zero' on;
    color: var(--label);
}

.Services .servicesbox:hover {
    transform: translate(0px, -20px);
}

/* Type Selection page  */
.AuthLayout {
    position: relative;
    margin-bottom: 80px;
}

.singlelogo {
    padding: 30px 70px;
    position: relative;
    z-index: 2;
}

.AuthLayout::after {
    content: '';
    width: 100%;
    height: 530px;
    background: var(--primary);
    position: absolute;
    top: 0;
    z-index: -1;
}

.UserTypeSelection-block {
    margin-top: 70px;
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 80px 50px 50px 50px;
}

.UserTypeSelection-block h4 {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    color: var(--label);
    margin-bottom: 60px;
}

.TypeSelectionCheckbox {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -18px;
}

.TypeSelectionCheckbox li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 9px;
}

.TypeSelectionCheckbox li .user-type-radio {
    padding: 0;
    margin: 0;
}

.workbar-box {
    padding-bottom: 150px;
}

.howworkbar {
    padding: 50px 50px 100px 50px;
    position: relative;
    background-image: url('../../../public/images/workbg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.howworkbar .aligncenter {
    align-items: center;
}

.howworkbar .workcontent {
    position: relative;
    padding-left: 130px;
}

.howworkbar .workcontent h3 {
    font-weight: 400;
    font-size: 48px;
    line-height: 48px;
    display: flex;
    align-items: center;
    color: var(--label);
    margin-bottom: 30px;
}

.howworkbar .workcontent p {
    font-weight: 400;
    font-size: 20px;
    line-height: 163.15%;
    display: flex;
    align-items: center;
    color: var(--label);
    margin-bottom: 40px;
}

.howworkbar .workcontent h2 {
    position: absolute;
    left: 0px;
    top: -90px;
    font-weight: 400;
    font-size: 228px;
    line-height: 228px;
    display: flex;
    align-items: center;
    color: var(--primary);
    margin: 0;
    opacity: 0.2;
}

.howworkbar .centerbar {
    margin: 190px 0;
}

.workbar-box {
    padding-bottom: 150px;
}

.Testimonails {
    padding: 50px 0;
    background: #F9F9F9;
}

.Testimonails h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 187.5%;
    color: var(--primary);
    margin: 0;
}

.Testimonails p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #525252;
}

/* The user-type-radio */
.user-type-radio {
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.user-type-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.user-type-radio .radiobar span.checkmark {
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid var(--borderColor);
    width: 20px;
    height: 20px;
}

/* On mouse-over, add a grey background color */
.user-type-radio:hover input~.checkmark {
    background-color: var(--darkgray);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.user-type-radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.user-type-radio input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.user-type-radio input:checked~.radiobar span.checkmark {
    background: url(../../../public/images/checkbox-done.svg);
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid var(--primary);
}

.user-type-radio input:checked~.radiobar,
.user-type-radio input:checked~.radiobar .typeicon,
.user-type-radio:hover .radiobar,
.user-type-radio:hover .radiobar .typeicon {
    border-color: var(--primary);
}

.user-type-radio .radiobar {
    background: var(--white);
    border: 0.5px solid var(--borderColor);
    border-radius: 15px;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: .3s ease;
}

.user-type-radio .radiobar .typeicon {
    width: 86px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: 1px solid var(--borderColor);
    margin-right: 24px;
    transition: .3s ease;
}

.user-type-radio .radiobar .userdetail h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: var(--label);
    margin-bottom: 5px;
}

.user-type-radio .radiobar .userdetail p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--textgray);
}

.user-type-radio .radiobar .typeicon img {
    filter: grayscale(1);
    transition: .3s ease;
}

.user-type-radio input:checked~.radiobar .typeicon img,
.user-type-radio .radiobar:hover .typeicon img {
    filter: grayscale(0);
}

.user-type-radio .radiobar:hover .typeicon img {
    transform: scale(1.3);
}

.user-type-radio input:checked~.radiobar img {
    transform: scale(1.3);
}

.UserTypeSelection-block .usertype-btngroups {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.UserTypeSelection-block .usertype-btngroups .btn-green {
    max-width: 350px;
    margin-bottom: 35px;
}

.UserTypeSelection-block .usertype-btngroups h6 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--label);
}

.linkbtn {
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}

.socialmedia-block {
    text-align: center;
    margin-top: 70px;
}

.socialmedia-block h5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 20px;
}

.SocialMedia {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SocialMedia li {
    margin: 0 10px;
}

.SocialMedia li a {
    width: 50px;
    height: 50px;
    border: 1px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: 26px;
    transition: .3s ease;
}

.SocialMedia li a:hover {
    background: var(--primary);
    transform: rotate(360deg);
    color: var(--white);
}

/* Register page css  */
.RegisterLayout {
    min-height: 100vh;
    background: var(--primary);
    position: relative;
}

.RegisterLayout .singlelogo {
    position: absolute;
    top: 40px;
    padding: 0;
    left: 50px;
}

.signup-block {
    height: 100%;
    padding: 36px 90px 35px 50px;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.signup-block .row {
    width: 100%;
}

.RegisterLayout-wrapper {
    min-height: 100vh;
    position: relative;
}

.registerform {
    max-width: 507px;
    margin: 0 0 0 auto;
    height: 100%;
    background: var(--white);
    border: 1px solid var(--white);
    border-radius: 30px;
    padding: 20px 40px;
}

.registerform .formtitlebar {
    text-align: center;
    margin-bottom: 30px;
}

.registerform .formtitlebar h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    color: var(--label);
    margin-bottom: 12px;
}

.registerform .formtitlebar p {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: var(--darkgray);
}

.theme-forminput {
    margin-bottom: 18px;
    position: relative;
}

.theme-formbtn a.btn-green {
    display: block;
}

.theme-forminput .form-label {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: var(--label);
}

.theme-forminput .country-select {
    display: block;
    width: 100%;
}

.theme-forminput .country-select input {
    width: 100%;
    padding: 16px 16px 16px 55px !important;
    border: 1px solid var(--borderColor);
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--label);
}

.theme-forminput .country-select input::placeholder {
    color: var(--form-placeholder);
}

.theme-forminput .input-group span.input-group-text {
    background: transparent;
    position: absolute;
    border: none;
    top: 50%;
    right: 5px;
    transform: translate(-50%, -50%);
    padding: 0;
    font-size: 24px;
    z-index: 10;
    margin: 0;
}

.registerform .formbtngroups {
    text-align: center;
    margin-top: 20px;
}

.signup-block ul.SocialMedia {
    position: fixed;
    bottom: 0;
    z-index: 10;
    left: 0;
    background: url('../../../public/images/socialmedia-bg.png');
    width: 380px;
    height: 80px;
    justify-content: flex-start;
    padding-left: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
}

.erroractive p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--danger);
    margin-top: 5px;
}

.erroractive .form-control,
.erroractive input {
    border-color: var(--danger);
}

.themechecboxbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.themechecboxbar input[type=checkbox] {
    width: 15px;
    height: 15px;
    margin-top: 0;
    margin-right: 10px;
    border: 1px solid var(--borderColor);
    border-radius: 2px;
    cursor: pointer;
}

.themechecboxbar label {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: var(--label);
    cursor: pointer;
}

body[data-page="/login"] .registerform .Confirmpassword {
    display: none;
}

.themechecboxbar input[type=checkbox]:focus-visible {
    box-shadow: 0 0 0 0.25rem rgb(87 175 116 / 31%) !important;
}

.orbar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 30px 0;
}

.orbar .line {
    width: 100%;
    height: 1px;
    background: var(--borderColor);
}

.orbar p {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--label);
    margin: 0 10px;
}

button.googlebtn img {
    margin-right: 10px;
    margin-top: -3px;
}

.headless-UI {
    position: relative;
    display: inline-block;
    text-align: left;
}

.headless-UI .uibtn {
    display: inline-flex;
    border: none;
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
}

/* Profile dropdown css  */
.profile-dropdown .dropdown-toggle {
    padding: 0;
    border: none;
    background: transparent;
}

.profile-dropdown {
    position: relative;
}

.profile-dropdown .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    background: var(--white);
    border: 2px solid var(--dropdown-border);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 10px;
}

.profile-dropdown .dropdown-menu a {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: var(--label);
    padding: 10px;
    justify-content: flex-start;
    border-radius: 5px;
    text-decoration: none;
}

.profile-dropdown .dropdown-menu a svg {
    margin-right: 10px;
    font-size: 20px;
}

.profile-dropdown .dropdown-menu a:hover {
    background: var(--bs-dropdown-link-hover-bg);
}

.MainHeader .navbar {
    background: var(--white);
    box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.07);
    padding: 5px 0;
}

.MainHeader .navbar .navbar-nav {
    align-items: center;
}

.MainHeader .navbar .navbar-nav li {
    margin-left: 30px;
}

.MainHeader .navbar .navbar-nav li button.clickeffect {
    background: var(--lightgray);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    border: none;
    font-size: 24px;
}

.menu-flags {
    padding: 0 !important;
}

.menu-flags button.ReactFlagsSelect-module_selectBtn__19wW7 {
    border: none;
    padding: 0;
    width: 170px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.menu-flags button.ReactFlagsSelect-module_selectBtn__19wW7 span.ReactFlagsSelect-module_selectValue__152eS {
    margin: 0 !important;
    padding: 0 5px 0 0;
}

.menu-flags button.ReactFlagsSelect-module_selectBtn__19wW7 span.ReactFlagsSelect-module_selectValue__152eS span.ReactFlagsSelect-module_selectFlag__2q5gC {
    font-size: 30px;
}

.menu-flags ul.ReactFlagsSelect-module_selectOptions__3LNBJ {
    background: var(--white);
    border: 2px solid var(--dropdown-border);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 10px;
    max-width: 100%;
    margin: 15px 0 0 0;
    font-size: 14px !important;
}

.menu-flags ul.ReactFlagsSelect-module_selectOptions__3LNBJ li {
    margin: 0 !important;
    border-radius: 5px;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    padding: 8px;
}

.menu-flags ul.ReactFlagsSelect-module_selectOptions__3LNBJ li:hover {
    background-color: #e9ecef;
}

.menu-flags ul.ReactFlagsSelect-module_selectOptions__3LNBJ li span.ReactFlagsSelect-module_label__27pw9 {
    font-size: 14px !important;
}

.menu-flags ul.ReactFlagsSelect-module_selectOptions__3LNBJ li span.ReactFlagsSelect-module_selectOptionValue__vS99- {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: var(--label);
}

.container-wrapper {
    max-width: 991px;
    margin: 0 auto;
}

.menu-flags ul.ReactFlagsSelect-module_selectOptions__3LNBJ li span.ReactFlagsSelect-module_selectOptionValue__vS99- span.ReactFlagsSelect-module_selectFlag__2q5gC {
    font-size: 30px;
    line-height: 1;
}

/* Account verification modals  */
.Accountverification-modal {
    display: flex !important;
    align-items: center;
}

.Accountverification-modal .modal-dialog {
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
}

.Accountverification-modal .modal-dialog .Accountverification {
    padding: 30px 50px;
    text-align: center;
}

.Accountverification-modal .modal-dialog .Accountverification img {
    margin-bottom: 26px;
}

.Accountverification-modal .modal-dialog .Accountverification h4 {
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;
    color: var(--label);
    margin-bottom: 14px;
}

.Accountverification-modal .modal-dialog .Accountverification p {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: var(--darkgray);
}

.Accountverification-modal .modal-dialog .Accountverification .accountverifybtn-bar {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
}

.Accountverification-modal .modal-dialog .Accountverification .accountverifybtn-bar .btn-unfill {
    margin: 15px 0;
}

.containerfluid {
    background: var(--white);
    padding: 46px 40px;
}

.main-wrapper {
    min-height: 100vh;
    padding: 40px;
    background: #f8f8f8;
}

.kindaempty {
    text-align: center;
}

.kindaempty h4 {
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    color: var(--label);
    margin-bottom: 20px;
}

.kindaempty p {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: var(--post-gray);
    margin-bottom: 30px;
}

.kindaempty .createjob-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 225px;
    margin: 0 auto;
}

.post-title {
    margin-bottom: 40px;
}

.post-title h4 {
    margin: 0;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    color: var(--label);
}


/* Post job tabs css  */
.jobpost-bar .nav-pills .nav-item {
    margin-bottom: 16px;
}

.jobpost-bar .nav-pills {
    position: sticky;
    top: 30px;
}

.jobpost-bar .nav-pills .nav-item .nav-link {
    background: #FCFCFC;
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 30px;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: var(--borderColor);
}

.jobpost-bar .nav-pills .nav-item .nav-link.active {
    background: #FAFFFC;
    border: 1px solid var(--primary);
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    color: var(--primary);
}

.jobpost-bar .tab-content {
    height: auto;
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 30px 40px;
    margin-bottom: 30px;
}

.main_question {
    display: flex;
    align-items: center;
}

.answer_question {
    display: flex;
    padding: 32px 52px;
    border-top: 1px solid #D1D5DB;
    border-bottom: 1px solid #D1D5DB;
    background: #F7F7F8;
}

.main_question p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #343541;
}

.text {
    width: 100%;
    text-align: start;
}

.question_ask {
    display: flex;
    justify-content: space-between;
    padding: 20px 52px;
}

.thumb_icons {
    display: flex;
}

.JobInfo {
    position: relative;
}

.img_logo img {
    height: 30px;
    width: 30px;
    overflow: hidden;
    object-fit: cover;
    margin-right: 24px;
}

.end_disclaimer {
    padding: 0 10px 30px;
}

.end_disclaimer p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    opacity: 0.5;
    line-height: 16px;
}

.send_bar {
    padding: 32px 58px 0;
}

.send_bar .input-group-text {
    background: white;
}

.send_bar .form-control {
    border-right: none !important;
}

.end_disclaimer p a {
    text-decoration: underline !Important;
}

.text p {
    color: #343541;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;

}

.thumb_icons svg {
    margin: 0 7px;
}

.job-title {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: var(--label);
    margin-bottom: 32px;
}

.JobInfo .theme-forminput .label-with-btn {
    margin-bottom: 10px;
}

.JobInfo .theme-forminput .label-with-btn .btn-green {
    padding: 10px 15px;
    width: auto;
    font-size: 12px;
    line-height: 14px;
}

.JobInfo .theme-forminput .label-with-btn .btn-green svg {
    margin-right: 10px;
    font-size: 18px;
}

.themeAi .modal-dialog {
    max-width: 870px;
}

.themeAi .modal-dialog .modal-body {
    padding: 0;
}

.themeAi .modal-dialog .modal-body .gptbar h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #10A37F;
    margin-bottom: 30px;
}

.themeAi .modal-dialog .modal-body .gptbar h4 svg {
    margin-right: 10px;
}

.themeAi .modal-dialog .modal-body .gptbar p,
.themeAi .modal-dialog .modal-body .gptbar ul li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #263238;
    margin-bottom: 30px;
}

.themeAi .modal-dialog .modal-body .gptbar ul li {
    margin-bottom: 6px;
}

.gptbar {
    padding: 36px 52px 10px;
}

.themeAi .modal-dialog .modal-body .gptbar ul {
    margin-bottom: 30px;
}

.JobInfo .theme-forminput .label-with-btn label.form-label {
    margin: 0;
    font-size: 16px;
}

.form_next_btn {
    display: flex;
    justify-content: flex-end;
}

.form_next_btn .btn-green {
    width: auto;
    padding: 14px 35px;
    display: inline-block;
}

.radio-bridge .form-check-inline {
    padding: 0;
    margin: 0 20px 0 0 !important;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
}

.radio-bridge .form-check-inline .form-check-input {
    background: var(--white);
    border: 1px solid var(--borderColor) !important;
    border-radius: 50px !important;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0px 8px 0 0;
    cursor: pointer;
}

.radio-bridge .form-check-inline .form-check-input:checked {
    background: none;
    border-width: 6px !important;
    border-color: var(--primary) !important;
}

.btnaddbar .btnadd {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary);
}

.btnaddbar {
    margin-top: 10px;
}

.btnaddbar .btnadd svg {
    margin-right: 5px;
}

.Main_Tabs_panel {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    display: inline-flex;
    padding: 5px;
}

.Main_Tabs_panel .nav-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #C4C4C4;
    padding: 8px 15px;
    border: 1px solid transparent !important;
    outline: none !important;
}

.Post_Main_Tabs_panel {
    flex-wrap: wrap;
    row-gap: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

ul.search_filterbar {
    flex: 0 0 66%;
    max-width: 66%;
    order: 2;
}

.Post_Main_Tabs_panel .Main_Tabs_panel {
    order: 1;
}

.Post_Main_Tabs_panel .tab-content {
    flex: 0 0 100%;
    order: 3;
    max-width: 100%;
}

.Chatbot .chat-wrapper .chatrow .msg-receiver ul .translate-bar {
    position: absolute;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
}

.Chatbot .chat-wrapper .chatrow .msg-receiver ul li {
    position: relative;
}

.Chatbot .chat-wrapper .chatrow .msg-receiver ul .translate-bar .translatebtn {
    background: #10A37F;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: var(--white);
    border: none;
    padding: 8px;
}

.Chatbot .chat-wrapper .chatrow .msg-receiver ul .translate-bar .translatebtn svg {
    margin-right: 5px;
    font-size: 20px;
}

.Chatbot .chat-wrapper .chatrow .msg-receiver ul li:hover .translate-bar {
    opacity: 1;
    visibility: visible;
}

.Chatbot .chat-wrapper .chatrow h6.time {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #4F5665;
    margin: 10px 0 0 48px;
    display: inline-block;
}

.Chatbot .chat-wrapper .chatrow .msg-sender h6.time {
    margin: 9px 0px 0 0;
    display: inline-block;
}

.btninfo {
    padding: 0;
    background: transparent !important;
    height: auto;
    border: none;
    color: var(--label) !important;
    margin: 0 0 0 10px;
    font-size: 20px;
    line-height: 1;
}

.theme-forminput.max-w-d {
    max-width: 430px;
    margin-bottom: 80px;
}

.GoldVerification form .aligninputwithbtn .btnadd {
    flex: 0 0 20%;
    max-width: 20%;
}

.table_heading table td {
    vertical-align: middle;
}

.btncenter .btn-green {
    width: auto;
}

.btncenter {
    text-align: center;
    margin-top: 30px;
}

.aligninputwithbtn.notalignbar {
    display: block !important;
}

.aligninputwithbtn.notalignbar .btn-green {
    width: auto;
}

.tooltip .tooltip-inner {
    background-color: var(--white) !important;
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
    max-width: 450px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 8px;
    text-align: left;
}

.radio-bridge {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.radio-bridge .infoiconbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 20px 0 0;
}

.radio-bridge .infoiconbar .btninfo {
    margin: 0;
}

.Main_Tabs_panel .nav-link.active {
    background: var(--lightgray);
    border-radius: 6px;
    color: var(--secondry);
}

.searchbar {
    position: relative;
}

.search_filterbar li {
    margin-left: 16px;
}

.filter-dropdown .dropdown-toggle .filtericon {
    width: 50px;
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-dropdown .dropdown-toggle::after {
    display: none;
}

.addmilestone .row {
    align-items: end;
}

.btndelete .btndelt {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    width: 50px;
    height: 50px;
    color: var(--primary);
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease;
}

.btndelete .btndelt:hover {
    background: var(--primary);
    border-color: var(--primary);
    color: var(--white);
}

.filter-dropdown .dropdown-menu {
    width: 230px;
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: 250px;
    overflow: scroll;
}

.filter-dropdown .dropdown-menu .filter-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--label);
    margin-bottom: 14px;
}

.filter-dropdown .dropdown-menu .theme-forminput {
    margin-bottom: 12px;
}

.filter-dropdown .dropdown-menu .theme-forminput .form-control {
    padding: 12px;
    font-size: 12px;
}

.filter-dropdown .dropdown-menu .theme-forminput label.form-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--label);
    margin-bottom: 5px;
}

.badges-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.badges-bar li {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.badges-bar li input {
    margin-right: 10px;
    margin-top: 0;
}

.primarytabs {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 20px 30px;
}

.primarytabs ul.tabhead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-bottom: 24px;
}

.primarytabs ul.tabhead li h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: var(--primary);
    margin-bottom: 5px;
}

.primarytabs ul.tabhead li h6 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: var(--post-gray);
}

.PostDetailList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 20px;
}

.PostDetailList li {
    margin-right: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--label);
}

.PostDetailList li button {
    padding: 5px 15px;
    border-radius: 50px;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
}

.PostDetailList li button.custom {
    background: #F8F8FF;
    color: #4540DB;
}

.PostDetailList li button.gold {
    background: #FFFCE2;
    color: #A89E39;
}

.primarytabs .PostDetailList {
    margin-bottom: 22px;
}

.PostDetailList li svg {
    font-size: 20px;
    color: var(--primary);
    margin-right: 7px;
}

.primarytabs .post-content {
    margin-bottom: 22px;
}

.post-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5E6670;
}

.PostDetailList li svg {
    font-size: 20px;
    color: var(--primary);
    margin-right: 7px;
}

.primarytabs .post-content {
    margin-bottom: 22px;
}

.post-content p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #5E6670;
}

ul.perposals-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 22px;
}

ul.perposals-list li {
    margin-right: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #5E6670;
}

ul.perposals-list li b {
    color: var(--secondry);
    margin-right: 5px;
}

.posttimebar p {
    font-size: 12px;
    line-height: 14px;
    color: #5E6670;
}

.primarytabs {
    margin-top: 20px;
}

.primarytabs ul.tabhead li .viewperposal-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter-dropdown .dropdown-menu .themechecboxbar label {
    font-size: 12px;
}

.filter-dropdown .dropdown-menu .themechecboxbar input[type=checkbox] {
    width: 12px !important;
    height: 12px !important;
    padding: 12px;
}

.pagelinking-dropdown .filtericon svg {
    color: var(--primary);
    font-size: 40px;
}

.supplierview-clientside .pagelinking-dropdown {
    margin-left: 20px;
}

.supplierview-clientside .pagelinking-dropdown {
    margin-left: 20px;
}

.supplierview-clientside .tabhead li .categoryname {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: var(--label);
    margin-bottom: 5px;
}

.supplierview-clientside .tabhead li h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 18px;
    color: var(--primary) !important;
}

.supplierview-clientside .Supplierdetail_ClientSide {
    display: flex;
    align-items: flex-start;
}

.supplierview-clientside .Supplierdetail_ClientSide .user-img {
    margin-right: 15px;
}

.supplierview-clientside .Supplierdetail_ClientSide .user-img img {
    width: 55px;
    height: 55px;
    object-fit: cover;
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part {
    width: 100%;
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part h4.name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--primary);
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part p.timezone {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--post-gray);
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part .supplier-active-milestone {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part .supplier-active-milestone ul.leftseen {
    margin-right: 60px;
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part .supplier-active-milestone ul.leftseen li.title {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #263238;
    margin-bottom: 5px;
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part .supplier-active-milestone ul.leftseen .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--post-gray);
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part .supplier-active-milestone ul.budgetbar {
    display: block;
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part .supplier-active-milestone ul.budgetbar li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 6px;
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part .supplier-active-milestone ul.budgetbar h6 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--label);
}

.supplierview-clientside .Supplierdetail_ClientSide .supplier-detail-part .supplier-active-milestone ul.budgetbar li h6:first-child {
    flex: 0 0 130px;
    font-weight: 500;
}

/* Job Posted css  */
.JobPosted .job-posted {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 26px;
    color: var(--label);
    margin-bottom: 28px;
}

.JobPosted .jobtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--label);
    margin-bottom: 8px;
}

.JobPosted .category {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--label);
}

.JobPosted .job-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5E6670;
    margin-top: 22px;
}

.sorted-dropdown .dropdown-item {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--label);
    padding: 10px 10px;
}

.ClientSidePerposal-tabpanel .paneltabcontent {
    padding: 25px 30px;
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    margin-top: 20px;
}

.ClientSidePerposal-tabpanel .paneltabcontent ul.PerposalHead {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 27px;
    flex-wrap: wrap;
    row-gap: 20px;
}

.ClientSidePerposal-tabpanel .paneltabcontent ul.PerposalHead li.head-leftbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ClientSidePerposal-tabpanel .paneltabcontent ul.PerposalHead li.head-leftbar img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    margin-right: 8px;
}

.ClientSidePerposal-tabpanel .paneltabcontent ul.PerposalHead li.head-leftbar .userdetail h5 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: var(--primary);
    margin: 0;
}

.ClientSidePerposal-tabpanel .paneltabcontent ul.PerposalHead li.head-leftbar .userdetail h5 span.rating {
    color: var(--label);
}

.ClientSidePerposal-tabpanel .paneltabcontent ul.PerposalHead li.head-leftbar .userdetail h5 span {
    margin-left: 8px;
}

.ClientSidePerposal-tabpanel .paneltabcontent ul.PerposalHead li.head-leftbar .userdetail p {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--post-gray);
}

.ClientSidePerposal-tabpanel .paneltabcontent ul.PerposalHead li.headrightbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ClientSidePerposal-tabpanel .paneltabcontent ul.PerposalHead li.headrightbar a {
    margin-right: 15px;
    width: auto;
}

.ClientSidePerposal-tabpanel .paneltabcontent .bidamountbar {
    margin-bottom: 22px;
}

.ClientSidePerposal-tabpanel .paneltabcontent .titleprime {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--label);
    margin-bottom: 10px;
}

.titleprime {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--label);
    margin-bottom: 10px;
}

.ClientSidePerposal-tabpanel .paneltabcontent .bidamountbar p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #5E6670;
}

.ClientSidePerposal-tabpanel .paneltabcontent .perposal-content {
    margin-bottom: 22px;
}

.ClientSidePerposal-tabpanel .paneltabcontent .perposal-content p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5E6670;
}

.perposal-content p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5E6670;
}

.ClientSidePerposal-tabpanel .paneltabcontent .perposal-attached-file .btn-unfill {
    width: auto;
}

.ClientSidePerposal-tabpanel .paneltabcontent .perposal-attached-file .btn-unfill svg {
    margin-left: 6px;
}

.initiatemodal .modal-footer {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 30px;
}

.initiatemodal .modal-footer button {
    width: auto;
    min-width: 160px;
    margin: 10px 10px 0 10px;
}

.initiatemodal .modal-body {
    padding: 30px 50px 20px 50px !important;
}

.containermax-width {
    max-width: 100%;
    padding: 0 50px;
    position: relative;
}

.Dispute .backbtn {
    position: absolute;
    left: 0;
    top: 0;
}

.Dispute .backbtn button {
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
}

.Dispute .dispute-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    color: var(--label);
    margin-bottom: 50px;
}

.Dispute .btndispute {
    justify-content: flex-end;
    display: flex;
    margin-top: 20px;
}

.Dispute .btndispute .btn-green {
    width: auto;
}

.Dispute .disputes-checkboxesbar {
    padding: 26px;
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
}

.ClientProfile {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 24px;
}

.ClientProfile .profilehead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.ClientProfile .profilehead ul.headleft {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ClientProfile .profilehead ul.headleft li.userprofile {
    margin-right: 30px;
}

.ClientProfile .profilehead ul.headleft li.userprofile img {
    width: 136px;
    height: 136px;
    border-radius: 50%;
    border: 3px solid transparent;
}

.ClientProfile .profilehead ul.headleft li.userprofile.active img {
    border-color: var(--primary);
}

.ClientProfile .profilehead ul.headright {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ClientProfile .profilehead ul.headright li a svg {
    margin-right: 10px;
}

.ClientProfile .profilehead ul.headleft li h5 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.189238px;
    color: var(--secondry);
    margin-bottom: 8px;
}

.ClientProfile .profilehead ul.headleft li p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--label);
}

.ClientProfile .profilehead ul.headleft li p span.location {
    font-size: 20px;
    margin-right: 5px;
    color: var(--primary);
}

.ClientProfile .tab-content {
    margin-top: 20px;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 24px;
}

.PostedJobContent .contenthead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PostedJobContent .contenthead h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: #263238;
}

.PostedJobContent .contenthead ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.PostedJobContent .contenthead ul .searchbar {
    margin-right: 15px;
}

.PostedJobContent .contenthead .btn-unfill svg {
    margin-right: 10px;
}

.PostedJobContent .contenthead .btn-unfill svg {
    margin-right: 10px;
}

.milestone-bar .milestone-block {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 24px;
}

.milestone-bar .milestone-block h4 {
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    color: var(--label);
    margin-bottom: 30px;
}

.milestone-bar .milestone-block .mile-history {
    position: relative;
}

.milestone-bar .milestone-block .mile-history ul {
    display: flex;
    align-items: flex-start;
    margin-bottom: 35px;
    position: relative;
    z-index: 2;
}

.milestone-bar .milestone-block .mile-history ul li.numericbar {
    margin-right: 26px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D4D4D4;
    border-radius: 90px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: var(--white);
}

.milestone-bar .milestone-block .mile-history ul li.numericbar.active {
    background-color: var(--primary);
}

.milestone-bar .milestone-block .mile-history ul.plusiconbar {
    align-items: center;
}

.milestone-bar .milestone-block .mile-history ul.plusiconbar li h4 {
    margin: 0;
    color: var(--primary);
}

.mile-history::after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    left: 15px;
    top: 0;
    background: #d4d4d4;
}

.milestone-bar .milestone-block .mile-history ul li h4 {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: var(--label);
}

.milestone-bar .milestone-block .mile-history ul li .milestone-payment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 25px;
}

.milestone-bar .milestone-block .mile-history ul li .milestone-payment h6 {
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--label);
    margin-right: 15px;
}

.milestone-bar .milestone-block .mile-history ul li .milestone-payment .status-badge {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #4CAF74;
    padding: 5px 8px;
    background: var(--borderColor);
    border: none;
    border-radius: 50px;
}

.milestone-bar .milestone-block .mile-history ul li h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--label);
    margin-bottom: 6px;
}

.milestone-bar .milestone-block .mile-history ul li p {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: var(--darkgray);
}

.milestone-bar .milestone-block .manage-milestone-btn a {
    width: auto;
}

.payment-history input[type="text"] {
    width: 100%;
    height: 20px;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
}

.milestone-bar .milestone-block .manage-milestone-btn a {
    width: auto;
}

.payment-history .progress {
    height: 8px;
    background: var(--borderColor) !important;
    border-radius: 8px;
}

.payment-history .progress .progress-bar {
    background: var(--primary);
    border-radius: 8px;
    position: relative;
}

.payment-history .progress .progress-bar.bg-custom::after {
    content: '';
    width: 20%;
    position: absolute;
    left: 0;
    top: 0;
    height: 8px;
    background: #00613E;
}

.milestone-bar .payment-history {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 24px;
}

.milestone-bar .payment-history h4 {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: var(--label);
}

.milestone-bar .payment-history ul {
    margin-top: 28px;
}

.milestone-bar .payment-history ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    position: relative;
    padding-left: 18px;
}

.milestone-bar .milestone-block .mile-history .ul-with-btn {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.milestone-bar .milestone-block .mile-history .ul-with-btn .btn-green {
    width: auto;
}

.milestone-bar .payment-history ul li h6 {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--label);
}

.milestone-bar .payment-history ul li::after {
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    content: '';
    border-radius: 50px;
    background: var(--borderColor);
}

.milestone-bar .payment-history ul li.receive::after {
    background: #00613E;
}

.milestone-bar .payment-history ul li.activefund::after {
    background: var(--primary);
}

.AddRating {
    padding: 80px 100px 40px 100px;
    position: relative;
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    margin-top: 20px;
}

.AddRating h4 {
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    color: var(--label);
    margin-bottom: 26px;
}

.AddRating p {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: var(--post-gray);
}

.AddRating ul.ratingstar-bar {
    margin-top: 50px;
}

.AddRating ul.ratingstar-bar li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--label);
}

.AddRating ul.ratingstar-bar li button {
    background: transparent;
    padding: 0;
    border: none;
    margin-right: 20px;
}

.AddRating ul.ratingstar-bar li button svg {
    color: var(--primary);
    margin-right: 10px;
    font-size: 24px;
    transition: .3s ease;
}

.AddRating form {
    margin-top: 40px;
}

.upload-btn {
    padding: 30px;
    background: rgba(76, 175, 116, 0.05);
    border: 1px dashed var(--primary);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-btn button.btn-green {
    width: auto;
}

.upload-btn button.btn-green svg {
    margin-right: 10px;
}

.AddRating form .btnsubmit button {
    width: auto;
    padding: 14px 45px;
}

.AddRating form .btnsubmit {
    text-align: center;
}

.ClientEditProfile {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 24px;
}

.ClientEditProfile .profilehead {
    margin-bottom: 40px;
}

.ClientEditProfileForm .btnsave {
    text-align: right;
}

.ClientEditProfileForm .btnsave button {
    width: auto;
    padding: 14px 30px;
}

.ChatbotLayout {
    position: relative;
}

.Chatbot {
    display: flex;
    height: calc(100vh - 95px);
    overflow: hidden;
}

.Chatbot .chatbotsidebar {
    flex: 0 0 360px;
    max-width: 360px;
    height: 100vh;
    overflow: scroll;
    padding-bottom: 200px;
}

.Chatbot .chatbotsidebar .chatsidebarhead {
    padding: 24px 30px 0 30px;
}

.Chatbot .chatbotsidebar h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 123.6%;
    display: flex;
    align-items: center;
    color: #263238;
    margin-bottom: 24px;
}

.Chatbot .chatbotsidebar .sidebar {
    margin-bottom: 0px;
}

ul.chating-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

ul.chating-box li.userimg {
    margin-right: 13px;
    width: 36px;
    flex: 0 0 36px;
}

ul.chating-box li.userimg img {
    width: 36px;
    height: 36px;
    object-fit: cover;
}

ul.chating-box li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 36px);
}

ul.chating-box li .detailbar-box .userdetail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

ul.chating-box li .detailbar-box .userdetail h5 {
    margin: 0 5px 0 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #141414;
}

ul.chating-box li .detailbar-box .userdetail span.status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--borderColor);
    display: inline-block;
    margin: 0 5px 0px 0;
}

ul.chating-box li .detailbar-box .userdetail .pending-msg {
    background: var(--primary);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--white);
    justify-content: center;
}

ul.chating-box li .detailbar-box .userdetail span.status.online {
    background: var(--primary);
}

ul.chating-box li .detailbar-box p {
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    align-items: center;
    color: #4F5665;
}

ul.chating-box li h6.msg-time {
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    align-items: center;
    color: #4F5665;
    margin: 0;
}

.Chatbot .chatbotsidebar .usermessages-detailbar {
    margin-top: 20px;
}

.Chatbot .chatbotsidebar .usermessages-detailbar ul.chating-box {
    padding: 10px 30px;
    transition: .3s ease;
}

.Chatbot .chatbotsidebar .usermessages-detailbar ul.chating-box:hover {
    background: #57af7403;
}

.Chatbot .chat-wrapper {
    width: 100%;
    border-left: 1px solid rgb(0 0 0 / 5%);
    border-right: 1px solid rgb(0 0 0 / 5%);
}

.Chatbot .chat-wrapper .chatwraper-head {
    padding: 23px 30px;
    border-bottom: 1px solid rgb(0 0 0 / 5%);

}

.Chatbot .chat-wrapper .chatwraper-head ul.chating-box .userdetail span.badgeimg {
    width: 18px;
}

.Chatbot .chat-wrapper .chatrow {
    height: 100%;
    padding: 24px;
    overflow: scroll;
}

.Chatbot .chat-wrapper .chatrow .msg-receiver {
    text-align: left;
}

.Chatbot .chat-wrapper .chatrow .msg-receiver ul {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.Chatbot .chat-wrapper .chatrow .msg-receiver ul li img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    margin-right: 10px;
}

.Chatbot .chat-wrapper .chatrow .msg-receiver ul li .msg {
    padding: 16px 30px;
    background: #F5F5F5;
    border-radius: 0px 16px 16px 0px;
    margin-top: 6px;
    display: inline-block;
    margin-left: 10px;
}

.Chatbot .chat-wrapper .chatrow .msg-sender {
    text-align: right;
    margin-top: 30px;
}

.Chatbot .chat-wrapper .chatrow .msg-sender ul li .msg {
    padding: 16px 30px;
    background: var(--primary);
    border-radius: 16px 16px 0px 10px;
    margin-top: 10px;
    display: inline-block;
}

.Chatbot .chat-wrapper .chat-typing-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-top: 1px solid rgb(0 0 0 / 5%);
    background: #ffffff;
}

.Chatbot .chat-wrapper {
    position: relative;
    padding-bottom: 200px;
}

.Chatbot .chat-wrapper .chat-typing-box .typing-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Chatbot .chat-wrapper .chat-typing-box .typing-input .inputin {
    position: relative;
    width: 100%;
    margin: 0 15px;
}

.Chatbot .chat-wrapper .chat-typing-box .typing-input .inputin button.imo-g {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0px, -50%);
    border: none;
    font-size: 24px;
    color: var(--primary);
    cursor: pointer;
}

.Chatbot .chat-wrapper .chat-typing-box .typing-input button.btnsend {
    border: none;
    background: transparent;
    font-size: 30px;
    color: var(--primary);
    cursor: pointer;
}

.Chatbot .chat-wrapper .chat-typing-box .typing-input .inputin input {
    border-radius: 50px;
}

.Chatbot .chat-wrapper .chat-typing-box button.btnplus {
    border: none;
    background: transparent;
    font-size: 48px;
}

.Chatbot .chatbotrytbar {
    padding: 40px 40px 200px 40px !important;
}

.Chatbot .chatbotrytbar .rytbar-user-detail {
    text-align: center;
    margin-bottom: 20px;
}

.Chatbot .chatbotrytbar .rytbar-user-detail img {
    width: 136px;
    height: 136px;
    object-fit: cover;
    margin-bottom: 13px;
}

.Chatbot .chatbotrytbar .rytbar-user-detail h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #141414;
    justify-content: center;
    margin-bottom: 4px;
}

.Chatbot .chatbotrytbar .rytbar-user-detail p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #4F5665;
}

.Chatbot .chatbotrytbar .action-btns {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.Chatbot .chatbotrytbar .action-btns button.btn-green {
    margin-bottom: 12px;
}

.shared-file .sharetilte {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.shared-file .sharetilte h4 {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: var(--label);
}

.shared-file .sharetilte a {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: var(--primary);
}

.shared-file .shared-files .col-md-3 {
    margin-bottom: 10px;
    padding: 0 5px;
}

.shared-file .shared-files {
    margin-bottom: 30px;
}

.shared-file .flagingbtn button.btn-green {
    margin-top: 12px;
}

.btn-del svg {
    margin-right: 8px;
}

.flagmodal .flagmodalbody {
    padding: 40px 45px;
}

.flagmodal .flagmodalbody h4 {
    margin-bottom: 30px;
}

.flagmodal .flagmodalbody .submitbtn {
    text-align: right;
}

.flagmodal .flagmodalbody .submitbtn button {
    width: auto;
    padding: 14px 30px;
}

.seller-profilebar {
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 15px;
    padding: 24px;
}

.seller-profilebar ul.PerposalHead {
    margin-bottom: 38px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    align-items: center;
    justify-content: space-between;
}

.seller-profilebar ul.PerposalHead li.head-leftbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 20px;
}

.seller-profilebar ul.PerposalHead li.head-leftbar .userimg {
    width: 136px;
    height: 136px;
    object-fit: cover;
    border: 2px solid var(--primary);
    border-radius: 50%;
    margin-right: 30px;
}

.seller-profilebar ul.PerposalHead li.head-leftbar .userdetail h5 {
    font-size: 24px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.189238px;
    justify-content: flex-start;
    color: #2A1E17;
    margin-bottom: 4px;
}

.seller-profilebar ul.PerposalHead li.head-leftbar .userdetail h5 span.verify-badge {
    margin-left: 10px;
}

.seller-profilebar ul.PerposalHead li.head-leftbar .userdetail .goldbadgetext {
    justify-content: flex-start;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    letter-spacing: 0.189238px;
    color: #2A1E17;
}

.seller-profilebar ul.PerposalHead li.head-leftbar .userdetail .goldbadgetext img {
    margin-right: 8px;
}

.seller-profilebar ul.PerposalHead li.head-leftbar .userdetail .goldbadgetext span {
    color: var(--primary);
    margin-left: 5px;
}

.seller-profilebar ul.PerposalHead li.head-leftbar .userdetail ul.seller-listing {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    justify-content: flex-start;
}

.seller-profilebar ul.PerposalHead li.head-leftbar .userdetail ul.seller-listing li {
    margin-right: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--label);
}

.seller-profilebar ul.PerposalHead li.head-leftbar .userdetail ul.seller-listing li span.location {
    margin-right: 8px;
    font-size: 18px;
    color: var(--primary);
}

.seller-profilebar .sellertabcontent {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 24px;
    margin-top: 20px;
}

.Products .products-title {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
}

.Products .products-title h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    color: var(--label);
    margin: 0;
    text-transform: capitalize;
}

.products-box {
    padding: 10px 6px;
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 4px;
    margin-bottom: 20px;
}

.products-box img {
    width: 100%;
    height: 110px;
    object-fit: contain;
    margin-bottom: 10px;
}

.products-box h4.price {
    font-style: normal;
    font-weight: 500;
    font-size: 13.7076px;
    line-height: 14px;
    color: #1C1C1C;
    margin-bottom: 5px;
}

.products-box h6.detail {
    font-weight: 400;
    font-size: 11.423px;
    line-height: 15px;
    letter-spacing: -0.126613px;
    color: #606060;
    margin-bottom: 5px;
}

.products-box p {
    font-weight: 400;
    font-size: 9.13838px;
    line-height: 8px;
    letter-spacing: -0.0653302px;
    color: var(--label);
}

.ManagementCertificate {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 24px;
    margin-bottom: 23px;
}

.ManagementCertificate h4 {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.189238px;
    color: #2A1E17;
    margin-bottom: 20px;
}

.ManagementCertificate ul {
    display: flex;
    flex-wrap: wrap;
}

.ManagementCertificate ul li {
    display: flex;
    padding: 4px 8px;
    border-radius: 50px;
    border: 1px solid var(--primary);
    color: var(--primary);
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    transition: all 0.4s ease-in-out;
}

.ManagementCertificate ul li:hover {
    background: var(--primary);
    color: white;
    transition: all 0.4s ease-in-out;
}

.li_parent {
    position: relative;
}

.toggle_hover {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
}

.ManagementCertificate ul li:hover .toggle_hover {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
}

.ManagementCertificate ul li:hover .toggle_p {
    visibility: hidden;
    z-index: -1;
    transition: all 0.4s ease-in-out;
}

.VerificationDetail {
    padding: 40px 60px;
    margin-top: 80px;
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
}

.VerificationDetail h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
    color: var(--label);
    margin-bottom: 50px;
    text-align: center;
}

.VerificationDetail .btnsubmit {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.VerificationDetail .btnsubmit .btn-green {
    width: auto;
    padding: 14px 40px;
}

.primarytabs .post-content .postedjob {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.primarytabs .post-content .postedjob img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 10px;
}

.primarytabs .post-content .postedjob h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--label);
    margin: 0;
}

.primarytabs .post-content .postedjob h4 span.name {
    color: var(--primary);
}

.primarytabs ul.tabhead li.tabryt-pricebid {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.primarytabs ul.tabhead li.tabryt-pricebid h5.price-bid {
    margin: 0 20px 0 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--label);
}

.viewjob-block ul.tabhead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.viewjob-block ul.tabhead li.jobleft h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--label);
    margin: 0;
}

.viewjob-block ul.tabhead li.jobleft h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--label);
    margin: 0;
}

.viewjob-block ul.tabhead li.jobleft .headprofile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
}

.viewjob-block ul.tabhead li.jobleft .headprofile img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    object-fit: cover;
}

.viewjob-block ul.tabhead li.jobleft .headprofile h4 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--label);
}

.viewjob-block ul.tabhead li.jobleft .headprofile h4 span.name {
    color: var(--primary);
}

.viewjob-block .jobcontentbar {
    margin-top: 40px;
}

.viewjob-block .jobcontentbar {
    margin-top: 40px;
}

.JobDescription h4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: var(--label);
    margin-bottom: 12px;
}

.JobDescription p,
.JobDescription ul li {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5E6670;
    margin-bottom: 16px;
}

.JobDescription ul li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 15px;
}

.JobDescription ul li::after {
    content: '';
    width: 5px;
    height: 5px;
    background: #5E6670;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    border-radius: 50%;
}

.Joboverview .overviewbox {
    padding: 30px;
    background: var(--white);
    border: 2px solid #E7F0FA;
    border-radius: 8px;
}

.Joboverview .amountbar ul {
    display: flex;
    align-items: center;
}

.Joboverview .amountbar ul li {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;
    position: relative;
}

.Joboverview .amountbar ul li h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--label);
    margin-bottom: 8px;
}

.Joboverview .amountbar ul li h5 {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: var(--primary);
}

.Joboverview .amountbar ul li .iconbar {
    margin-bottom: 10px;
}

.Joboverview .amountbar ul li h5.location {
    color: var(--post-gray);
}

.Joboverview .amountbar ul li .iconbar svg {
    font-size: 32px;
    color: var(--primary);
}

.Joboverview .amountbar ul li.border-ryt::after {
    content: '';
    width: 2px;
    height: 100px;
    background: #E7F0FA;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
}

.joboverbar {
    margin-top: 30px;
}

.joboverbar h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: var(--label);
    margin-bottom: 20px;
}

.joboverbar ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.joboverbar ul li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 20px;
    padding: 0 10px;
}

.joboverbar ul li svg {
    font-size: 26px;
    margin-bottom: 10px;
    color: var(--primary);
}

.joboverbar ul li h5 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--post-gray);
    margin-bottom: 4px;
}

.joboverbar ul li h6 {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--label);
}

.ApplyJob .tabryt-pricebid .viewperposal-btn {
    display: none !important;
}

.Joboverview .amountbar ul li h5.location {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--post-gray);
}

.ApplyJob .tabryt-pricebid .viewperposal-btn {
    display: none !important;
}

.ApplyJob .milestone-bar {
    margin-top: 20px;
}

.ApplyJob .milestone-bar .milestone-block h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 20px;
}

.ApplyJob .milestone-bar .milestone-block p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #5E6670;
    margin-bottom: 40px;
}

.ApplyJob .milestone-bar .milestone-block ul:last-child {
    margin-bottom: 0;
}

.borderbox {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 24px;
    margin-top: 20px;
}

.setbid h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: var(--label);
    margin-bottom: 24px;
}

.setbid .theme-forminput {
    margin: 0;
}

.setbid .label-with-btn .btnuplaod {
    width: auto;
    position: relative;
    overflow: hidden;
    padding: 14px 30px;
}

.setbid .label-with-btn .btnuplaod input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.setbid .label-with-btn .btnuplaod svg {
    margin-right: 10px;
}

.ApplyJob .btnsubmit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.ApplyJob .btnsubmit .btn-green {
    width: auto;
}

.PerposalSubmit {
    text-align: center;
}

.PerposalSubmit img {
    margin-bottom: 10px;
}

.PerposalSubmit h4 {
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    color: var(--label);
    margin-bottom: 20px;
}

.PerposalSubmit p {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: var(--post-gray);
}

.PerposalSubmit .morepost {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.PerposalSubmit .morepost .btn-green {
    width: auto;
}

.profileimgedit {
    position: relative;
    width: 136px;
    height: 136px;
    border-radius: 50%;
}

.profileimgedit button.imgadd {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: var(--white);
    border: none;
    font-size: 26px;
    position: absolute;
    bottom: -5px;
    right: -5px;
}

.profileimgedit button.imgadd input[type="file"] {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.profileimgedit img {
    border: 3px solid var(--primary);
    border-radius: 50%;
    width: 136px;
    height: 136px;
    object-fit: cover;
}

.seller-profilebar .perposal-content {
    margin-bottom: 30px;
}

.Products .products-title .rytbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.seller-profilebar .sellertabcontent {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 24px;
}

.Products .products-title h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    color: var(--label);
    margin: 0;
    text-transform: capitalize;
}

.Products .products-title .rytbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Products .products-title .rytbar .searchbar {
    margin-right: 20px;
}

.Products .products-title .rytbar .btn-green {
    width: auto;
}

.reviewtabblock {
    padding: 16px;
    background: var(--white);
    border: 0.72111px solid var(--borderColor);
    border-radius: 10px;
}

.reviewtabblock .reviewhead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.reviewtabblock h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: var(--primary);
}

.reviewtabblock p {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.02em;
    color: #5E6670;
}

.reviewtabblock .replybtnsingle {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.reviewtabblock .replybtnsingle .btnreply {
    border: none;
    background: transparent;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.02em;
    cursor: pointer;
    color: var(--primary);
}

.reviewtabblock .replybtnsingle .btnreply svg {
    margin-right: 5px;
}

.reviewtabblock .replybar {
    margin-top: 10px;
}

.reviewtabblock .replybar .theme-forminput {
    margin-bottom: 8px;
}

.reviewtabblock .replybar .replybtns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.reviewtabblock .replybar .replybtns button {
    width: auto;
    margin-left: 10px;
    padding: 10px 16px;
    font-size: 14px;
}

.addproductleftbar {
    background: #F6FBF8;
    border: 1px dashed var(--primary);
    border-radius: 10px;
    height: 565px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
}

.addproductleftbar p {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #A6A6A6;
    margin-bottom: 20px;
}

.addproductleftbar button.btn-green {
    width: auto;
    position: relative;
}

.addproductleftbar button.btn-green svg {
    margin-right: 10px;
}

.addproductleftbar button.btn-green input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.addproductleftbar button.btn-green input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.addproductRytbar .checkbar-collection-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.checkbar-collection-bar button.btn-green {
    padding: 12px;
    width: auto;
}

.checkbar-collection-bar button.btn-green svg {
    margin-right: 10px;
}

.checkbar-collection-bar .theme-forminput {
    margin: 0;
}

.addproductRytbar .PostProductbtn {
    display: flex;
    justify-content: flex-end;
}

.addproductRytbar .PostProductbtn a.btn-green {
    width: auto;
}

.GoldVerification {
    position: relative;
}

.GoldVerification h6 {
    text-align: center;
    font-weight: 400;
    font-size: 23.6962px;
    line-height: 24px;
    color: var(--label);
    margin-bottom: 16px;
}

.GoldVerification h4 {
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
    color: var(--label);
    margin-bottom: 10px;
}

.GoldVerification p {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: var(--post-gray);
}

.GoldVerification form {
    position: relative;
    margin-top: 40px;
}

.GoldVerification form .upload-btn {
    flex-direction: column;
}

.GoldVerification form .upload-btn p {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #A6A6A6;
    margin-bottom: 16px;
}

.GoldVerification form .aligninputwithbtn .btnadd .btn-green svg {
    margin-right: 10px;
}

.GoldVerification form .upload-btn button.btn-green {
    position: relative;
}

.GoldVerification form .upload-btn button.btn-green input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.GoldVerification form .aligninputwithbtn {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    justify-content: space-around;
}

.GoldVerification form .aligninputwithbtn .btnadd .btn-green {
    position: relative;
    overflow: hidden;
}

.GoldVerification form .aligninputwithbtn .btnadd .btn-green input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.GoldVerification form .aligninputwithbtn .theme-forminput {
    margin: 0;
    flex: 0 0 80%;
    padding-right: 20px;
    max-width: 80%;
}

.GoldVerification form .aligninputwithbtn .btnadd .btn-green svg {
    margin-right: 10px;
}

.GoldVerification form .paymentbtn {
    margin-top: 30px;
    text-align: center;
}

.GoldVerification form .paymentbtn .btn-green {
    width: auto;
}

.GoldVerification h6 span {
    font-size: 14px;
    color: var(--primary);
}

.GoldVerification h6 span {
    font-size: 14px;
    color: var(--primary);
}

.bidsubscription h4 {
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.008em;
    color: #263238;
    margin-bottom: 70px;
}

.bidsubscription h4 span {
    color: var(--primary);
}

.bidplan {
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 10px;
    padding: 40px 24px;
    text-align: center;
    transition: .3s ease;
}

.bidplan:hover {
    background: rgba(76, 175, 116, 0.1);
    transform: translate(0px, -10px);
}

.bidplan h5 {
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 12px;
    color: var(--label);
}

.bidplan p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #2E3630;
    min-height: 72px;
}

.bidplan h3 {
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    color: var(--label);
}

.bidplan h3 span {
    font-size: 20px;
    font-weight: normal;
}

.bidplan .bidbtn {
    border: 1px solid var(--label);
    border-radius: 5px;
    padding: 10px 20px;
    background: transparent;
    transition: .3s ease;
    cursor: pointer;
}

.bidplan:hover .bidbtn {
    background: var(--primary);
    color: var(--white);
    border-color: var(--primary);
}

.ManageMilestonebox {
    background: --white;
    border: 1px solid var(--borderColor);
    border-radius: 15px;
    padding: 24px;
    margin-bottom: 40px;
}

.milestonebtn {
    text-align: right;
}

.milestonebtn .btn-green {
    width: auto;
}

.MainHeader .navbar .navbar-nav li .dropdown .dropdown-toggle svg {
    color: var(--secondry);
}

.MainHeader .navbar .navbar-nav li .dropdown .dropdown-toggle svg {
    color: var(--secondry);
}

.MainHeader .navbar .navbar-nav li .dropdown .dropdown-menu {
    width: 260px;
}

.MainHeader .navbar .navbar-nav li .dropdown .dropdown-menu ul.chating-box li.userimg {
    margin: 0 10px 0 0 !important;
    flex: 0 0 24px;
}

.MainHeader .navbar .navbar-nav li .dropdown .dropdown-menu ul.chating-box li {
    margin: 0 0 0 0 !important;
}

.MainHeader .navbar .navbar-nav li .dropdown .dropdown-menu ul.chating-box li.userimg img {
    width: 24px;
    height: 24px;
}

.MainHeader .navbar .navbar-nav li .dropdown .dropdown-menu ul.chating-box .detailbar-box .userdetail h5 {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #141414;
}

.MainHeader .navbar .navbar-nav li .dropdown .dropdown-menu ul.chating-box .detailbar-box p {
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    color: #4F5665;
}

.relative.gray-field-bar {
    padding: 15px;
    background: #FCFCFC;
    border: 1px solid #E2E2E2;
    border-radius: 15px;
    margin-bottom: 20px;
}

.relative.gray-field-bar .theme-forminput {
    margin: 0;
}

.datepicker .react-datepicker-popper {
    width: 100%;
}

.datepicker .react-datepicker-popper .react-datepicker .react-datepicker__day.react-datepicker__day--selected {
    background: var(--primary);
}

.MainHeader .navbar .navbar-nav li .dropdown .dropdown-item {
    padding: 10px 10px;
}

.MainHeader .navbar .navbar-nav li .dropdown .viewall {
    justify-content: center;
    color: var(--primary);
    background: transparent !important;
}

.MainHeader .navbar .navbar-nav li .dropdown .viewall:hover {
    color: var(--secondry);
}

.onlinestatus {
    position: relative;
}

.onlinestatus ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 5px;
}

.onlinestatus ul li {
    margin: 0 !important;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #C4C4C4;
    justify-content: center;
    display: flex;
    align-items: center;
    background: transparent;
    transition: .3s ease;
    position: relative;
    padding: 8px;
    border-radius: 5px;
}

.onlinestatus ul li span {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #C4C4C4;
    margin-right: 5px;
}

.onlinestatus ul li.active {
    background: var(--lightgray);
    color: var(--secondry);
}

.onlinestatus ul li.Online span {
    background: var(--primary) !important;
}

.onlinestatus ul li.Offline span {
    background: var(--post-gray);
}

.onlinestatus ul li.Away span {
    background: #E8C95B;
}

body[data-page="/productdetail"] .containermax-width {
    padding: 0;
}

.product-slider .thumbnailslider ul.control-dots {
    display: none;
}

.product-slider .thumbnailslider p.carousel-status {
    display: none;
}

.product-slider .thumbnailslider .control-arrow {
    opacity: 1;
    background: transparent !important;
}

.product-slider .carousel-root .carousel .thumbs-wrapper {
    margin: 10px 0 0 0 !important;
}

.product-slider .carousel-root .carousel .thumbs-wrapper li.thumb {
    border: 2px solid transparent !important;
    margin-right: 8px !important;
    padding: 0 !important;
}

.product-slider .carousel-root .carousel .thumbs-wrapper li.thumb.selected {
    border-color: var(--primary) !important;
}

.product-slider .productdetail .name {
    font-size: 25.181px;
    line-height: 38px;
    letter-spacing: 0.25181px;
    color: var(--label);
    margin-bottom: 10px;
}

.product-slider .productdetail .ratingbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.product-slider .productdetail .ratingbar h6 {
    font-weight: 400;
    font-size: 17.6267px;
    line-height: 30px;
    letter-spacing: 0.25181px;
    color: #737373;
    margin: 0 0 0 14px !important;
}

.product-slider .productdetail .productby,
.product-slider .productdetail .availabilitybar {
    margin-bottom: 24px;
}

.product-slider .productdetail .productby h3,
.product-slider .productdetail .availabilitybar h3 {
    margin: 0;
    font-weight: 400;
    font-size: 18.4424px;
    line-height: 38px;
    letter-spacing: 0.25181px;
    color: var(--label);
}

.product-slider .productdetail .productby h3 span {
    color: var(--primary);
}

.product-slider .productdetail ul.products-tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    align-items: center;
    justify-content: flex-start;
}

.product-slider .productdetail ul.products-tabs li {
    border: 1px solid var(--primary);
    color: var(--primary);
    padding: 4px 16px;
    border-radius: 20px;
    margin-right: 10px;
}

.product-slider .productdetail .pricing {
    margin-bottom: 10px;
}

.product-slider .productdetail .pricing h4 {
    margin: 0;
    font-weight: 500;
    font-size: 30.2172px;
    line-height: 40px;
    letter-spacing: 0.125905px;
    color: var(--label);
}

.product-slider .productdetail .pricing h4 span {
    font-size: 18px;
}

.product-slider .productdetail .availabilitybar h3 span {
    color: var(--primary);
}

.product-slider .productdetail .moq {
    font-weight: 400;
    font-size: 20.4916px;
    line-height: 25px;
    letter-spacing: 0.25181px;
    color: var(--label);
}

.product-slider .productdetail .product-content {
    font-weight: 400;
    font-size: 17.6267px;
    line-height: 25px;
    letter-spacing: 0.25181px;
    color: #858585;
}

.product-slider .productdetail .btnbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;
}

.product-slider .productdetail .btnbar .btn-green,
.product-slider .productdetail .btnbar .btn-del {
    width: auto;
    margin-right: 15px;
}

.relatedproducts {
    margin-top: 40px;
}

.relatedproducts h4 {
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    letter-spacing: 0.122409px;
    text-transform: capitalize;
    color: #252B42;
    margin-bottom: 30px;
}

.products-box a {
    text-decoration: none;
}

.PrimaryHeader {
    background: var(--white);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 0px 40px 10px rgba(0, 0, 0, 0.05);
    border-radius: 0px;
    padding: 30px 0;
}

.PrimaryHeader .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PrimaryHeader .navbarlinks ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
}

.PrimaryHeader .navbarlinks ul li {
    margin-left: 30px;
}

.PrimaryHeader .navbarlinks ul li a.btn-simple {
    text-decoration: none;
    color: var(--primary);
}

.ReviewSlider {
    padding: 40px 0 10px 0 !important;
}

.ReviewSlider .sliderin {
    background: var(--white);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 22.2222px;
    padding: 30px;
    margin: 0 10px;
    min-height: 448px;
}

.ReviewSlider .sliderin img {
    width: auto;
    margin-bottom: 15px;
}

.slick-slide img {
    max-width: 73px !important;
}

.ReviewSlider .sliderin h4 {
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    color: var(--label);
    margin-bottom: 10px;
}

.ReviewSlider .sliderin p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #525252;
}

.horizontalSlider___281Ls.carousel__slider.carousel__slider--horizontal {
    background: transparent;
    padding: 40px 0;
}

.ReviewSlider .slick-arrow {
    position: absolute;
    top: -92px;
    background: var(--white);
    box-shadow: 0px 4.44444px 26.6667px rgba(0, 0, 0, 0.12);
    right: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: none;
    left: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    opacity: 1 !important;
    cursor: pointer;
}

.ReviewSlider .slick-dots {
    display: none !important;
}

.ReviewSlider .slick-arrow.slick-2 {
    right: 70px;
}

.ReviewSlider .carousel .nextbtn {
    right: 100px;
}

.ReviewSlider .carousel .btn-nav svg {
    font-size: 20px;
}

.ReviewSlider .carousel .btn-nav svg {
    font-size: 20px;
}

.Faqs {
    padding: 200px 0;
    position: relative;
}

.Faqs .Faqsleft h5 {
    font-weight: 500;
    font-size: 48px;
    line-height: 70px;
    color: var(--primary);
    margin-bottom: 20px;
}

.Faqs .Faqsleft p {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    color: var(--label);
    opacity: 0.87;
}

.Faqs .Faqsryt .accordion-item {
    border: none;
}

.Faqs .Faqsryt .accordion-item .accordion-button {
    background: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: var(--label);
    padding: 20px 0 20px 0;
    border-bottom: 2.49355px solid #ECECF1;
}

.Faqs .Faqsryt .accordion-item .accordion-button span {
    margin-right: 40px;
    color: #4CAF74;
}

.Faqs .Faqsryt .accordion-item .accordion-body {
    padding: 10px 70px 30px 70px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--label);
    opacity: 0.87;
}

.Startedtheme {
    max-width: 1024px;
    margin: 0 auto 70px auto;
    padding: 40px 30px;
    position: relative;
    background-image: url('../../../public/images/pattern.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.Startedtheme .started-left {
    flex: 0 0 62%;
    max-width: 62%;
}

.Startedtheme .started-left h4 {
    font-weight: 500;
    font-size: 42px;
    line-height: 48px;
    text-transform: capitalize;
    color: var(--white);
    margin: 0 0 5px 0;
}

.Startedtheme .started-left p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--white);
}

.Startedtheme .started-form {
    width: 100%;
}

.Startedtheme .started-form .theme-forminput {
    margin: 0;
    display: flex;
    align-items: center;
}

.Startedtheme .started-form .theme-forminput .addbtn {
    position: absolute;
    right: 5px;
    width: auto;
    padding: 10px 16px;
    font-size: 14px;
}

.Startedtheme .started-form .theme-forminput input {
    padding-right: 105px;
}

.LandingFooter {
    padding: 30px 0;
    background: var(--primary);
}

.LandingFooter .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LandingFooter .container ul.footer-navs {
    display: flex;
}

.LandingFooter .container ul.footer-navs li {
    margin-left: 50px;
}

.LandingFooter .container ul.footer-navs li a {
    color: var(--white);
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
}

.howworkbar .workcontent a {
    font-weight: 400;
    font-size: 16px;
    line-height: 175.7%;
    text-decoration-line: underline;
    color: var(--primary);
}

.howworkbar .workcontent a svg {
    margin-left: 10px;
}

.radio-bridge-bar {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio-bridge-bar input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-bridge-bar .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

.container:hover input~.checkmark {
    background-color: #ccc;
}

.radio-bridge-bar input:checked~.checkmark {
    background-color: #2196F3;
}

.radio-bridge-bar .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-bridge-bar input:checked~.checkmark:after {
    display: block;
}

.radio-bridge-bar .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

/* ------------------- Admin layout----------------- */
.sidebarhead ul li {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.sidebarhead ul li a {
    text-decoration: none;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    padding: 15px;
    border: 4px solid transparent;
}

.sidebarhead ul {
    padding-left: 0;
    justify-content: center;
}

.dashboardsidebar {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--white);
    height: 100vh;
    border-right: 1px solid #b2b2b25e;
    min-width: 230px;
    transition: all 0.4s ease-in-out;
}

.logobar img {
    display: flex;
    margin: auto;
}

.sidebar-panel .logobar img {
    padding: 25px 15px;
    height: 150px;
    width: auto;
}

.table_heading {
    flex-wrap: wrap;
    padding: 31px 20px 24px 22px;
}

.table_heading h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
}

.sidebarhead ul li a svg {
    margin: 0 0.5rem;
}

.sidebarhead ul li a.active {
    background: rgba(76, 175, 116, 0.05);
    ;
    border-left: 4px solid var(--primary);
    color: var(--primary);
    border-radius: 0 8px 8px 0;
}

.main_dashboard_block {
    display: block;
}

.dashboard_wrapper {
    display: flex;
}

.mainoutlet {
    width: 100%;
    margin-left: 230px;
    padding: 0;
}

.top_navbar {
    display: none;
}

.main_dashboard_block .navbar {
    padding: 20px 30px;
}

/* ----------------------- Table css -------------------- */
.adminooutlet table {
    table-layout: inherit;
    font-size: 14px;
}

.popover_suppliers a {
    text-decoration: none;
    color: black;
    display: block;
    padding: 9px 0;
}

.popover_suppliers {
    padding: 5px;
}

.adminooutlet table thead {
    background: var(--primary);
    color: white;
    height: 56px;
}

.adminooutlet table tr a {
    text-decoration: none;
    color: var(--dark);
}

.adminooutlet table tr th {
    border: none;
}

.adminooutlet table thead tr th {
    font-weight: 500;
}

.adminooutlet .table>:not(caption)>*>* {
    padding: 18px 20px !important;
}

.adminooutlet table tbody tr td a:hover {
    color: #DE4724;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: var(--dark) !important;
    cursor: pointer !important;
}

/* ------------------------- dashboard css -------------------- */
.acolor {
    color: var(--primary) !important;
    text-decoration: none;
}

.card_dashboard {
    display: flex;
}

.card_dashboard img {
    margin-right: 18px;
}

.text_data p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 14px;
    color: var(--primary);
    font-weight: 500;
}

.text_data h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 8px;
}

.cardcontainer .card-body {
    padding: 20px 14px !important;
}

.disputes {
    border: 1px solid rgba(128, 128, 128, 0.304);
    padding: 24px 34px;
    background: #FBFBFB;
    border-radius: 14px;
    /* max-width: 582px; */
}

.disputes .row {
    height: 66px;
    border-bottom: 1px solid #cccccca0;
    padding: 20px 0;
}

.header_diputes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}

.header_diputes p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
}

.dashboard_layout {
    padding: 50px;
    margin-left: 232px;
    width: 100%;
}

.border_background {
    border: 1px solid rgba(128, 128, 128, 0.328);
    padding: 24px;
    margin: 30px 0;
    background: #FBFBFB;
    border-radius: 14px;
}

.suppliers_bar .row {
    padding: 14px 0 !important;
    font-size: 16px;
}

.suppliers_bar .row>* {

    padding-right: 0 !important;
    padding-left: 0 !important;
}

.suppliers_bar {
    padding: 35px;
}

.suppliers_bar p {
    margin: 9px 0;
}

.buttons_graph {
    display: inline-flex;
    justify-content: end;
    width: 100%;
}

/* -------------------- commission page cs ------------------- */

.commission_amount h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 4.7rem;
    line-height: 100px;
    padding-top: 15px;
}

.commission_amount button {
    height: 50px;
    width: 193px;
    background: #4CAF74;
    border: none !important;
}

.last_transactions {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.main_container_transactions {
    display: flex;
    align-items: center;
}

.commission_amount button:hover {
    background: #10A37F;
    cursor: pointer;
}

.commission_amount h5 {
    font-size: 1.1rem;
}

.comsn_amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* -------------------- verification page----------------- */
.verifybtn {
    width: 95px;
    height: 34px;
    border: none;
    color: white;
    margin: 0 7px;
}

.input_verify {
    max-width: 292px;
    margin: 28px 0 10px;
}

.input_verify .input-group-text {
    opacity: 0.5;
}

.green {
    background: #4CAF74;
}

.green_a {
    color: var(--primary) !important;
}

.red {
    border: 1px solid red;
    color: red;
    background-color: transparent;
}

.searchbar_2 {
    align-items: center;
    display: flex;
    height: 40px;
}

.searchbar_2 input {
    padding: 10px 112px 10px 27px;
}

/* ---------------- document verification---------------- */
.maindiv_documents {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
}

.button_doc_verification button {
    border: 1px solid #4CAF74 !important;
    color: #4CAF74 !important;
    border-radius: 50px !important;
    background: white !important;
    padding: 8px 16px !important;
    transition: all 0.4s ease-in-out;
}

.button_doc_verification button:hover {
    background-color: #4CAF74 !important;
    color: white !important;
}

.maindiv_documents p {
    color: #979797;
}

.showw {
    display: flex !important;
    align-items: center;
    transition: 0.4s ease-in-out;
}

.show {
    transition: 0.4s ease-in-out;
}

.hidden {
    display: none !important;
}

.ManagementCertificate button {
    border-radius: 15px;
    background: var(--primary);
    padding: 5px 16px;
    border: none;
    display: flex;
    color: white;
}

.border {
    border: 1px solid #E2E2E2 !important;
    border-radius: 12px !important;
    padding: 30px;
}

.modalbody {
    padding: 37px 59px !important;
}

.docs h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.docs p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
}

/* -------------- disputes page------------- */

.disputes_info {
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E2E2E2;
    border-radius: 12px;
    padding: 24px 30px;
}

.disputes_info p {
    font-size: 12px;
    opacity: 0.7;
}

.disputes_info b {
    font-size: 16px;
    font-weight: 500;
}

.tabs_dispute {
    margin: 30px 0 20px;
}

.dispute_title {
    font-size: 30px;
}

.head_nav {
    padding: 0 73px;
}

.head_bids {
    padding: 8px 14px;
    color: #10A37F;
    background: hsla(165, 87%, 42%, 0.137);
    border-radius: 4px;
    border: 1px solid var(--primary);
}

.limarginright {
    margin-right: -60px;

}

.main_div_id_payment {
    border: 1px solid #E2E2E2;
    border-radius: 12px;
    padding: 34px;
}

.data_id_payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
}

.data_id_payment b {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.data_id_payment p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #263238;
}

.paymentimagebox {
    display: flex;
    align-items: center;
    padding: 24px 0;
}

.paymentimagebox p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}

.paymentimage {
    margin-right: 18px;
}

.wholerowpayment {
    padding: 24px 5px;
    border-bottom: 1px solid #E2E2E2;
}

.smalltextpayment {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px;
    opacity: 0.7;
}

.below_text_payment {
    opacity: 0.7;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.chooser {
    padding: 8px;
    height: 56px;
    margin: 17px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonoffilechooser button {
    padding: 5px 11px;
    border: 1px solid grey;
    border-radius: 4px;
}

.modal_button_ {
    display: flex;
    justify-content: center;
    margin-top: 28px;
}

.modal_button_ button {
    padding: 18px 55px;
    background-color: #10A37F;
    color: white;
    border: none;
    border-radius: 5px;
}


/* ------------- payment history------------ */
.table_payment_history table td {
    height: 56px;
    vertical-align: middle;

}

.table_payment_history table th {
    height: 48px;
    vertical-align: middle;
    background: #4CAF74;
    color: white;
}

.table_payment_history {
    margin: 30px 0;
}

.table_payment_history table tr th:first-child {
    border-radius: 6px 0 0 0;

}

.table_payment_history table tr th:last-child {
    border-radius: 0 6px 0 0;

}

.table_payment_history tr:nth-of-type(even) {
    background: rgba(183, 183, 183, 0.146);
}

/* ------------------- horizontal jobover--------------- */
.widthamountbar {
    width: 100%;
}

.mt_0 {
    margin-top: 0 !important;
}

/* ----------------- attachments---------------- */
.attachment_images_box {
    display: flex;
    flex-wrap: wrap;
}

.attachment_image img {
    width: 100%;
    height: 158px;
    border-radius: 10px;
}

.attachment_image {
    position: relative;
    overflow: hidden;
    margin: 0 8px 16px 8px;
    height: 157px;
    border-radius: 10px;
    width: calc(14.2% - 16px);
}

.overlay_attachment {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.584);
    opacity: 0;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 10px;

}

.overlay_attachment p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
}

.attachment_image:hover .overlay_attachment {
    top: 0;
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.attachments_main {
    border: 1px solid rgba(205, 205, 205, 0.362);
    padding: 33px;
    border-radius: 12px;
    margin: 60px 0;
}

.overlay_attachment img {
    display: flex;
    margin: 16px auto 50px;
    width: auto;
    height: auto;
}

.chart_buttons button {
    color: rgba(169, 169, 169, 0.534);
}

.chart_buttons button:focus {
    border-bottom: 3px solid #10A37F;
    border-radius: 0 !important;
    color: #000000;
}

/* ..... new css,,,,,,, */
.payment_methods input {
    max-width: 275px;
}

.payment_image_box {
    display: flex;
    align-items: center;
    object-fit: contain;
    max-width: 112px;
    height: 73px;
    border-radius: 8px;
}

.methods_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.payment_image_box:active {
    border: 1px solid green;
    max-width: 109px;
}

/* ---------------- blog card--------------- */
.main_card_blog {
    margin: 0 10px;
    width: 438px;
}

.main_card_blog .card {
    height: 572px;
}

.main_card_blog img {
    max-width: 100% !important;
}

.main_card_blog .badge_top {
    background: #DFF1F0 !important;
    padding: 6px 8px !important;
    margin: 8px 0;
    color: #666666;
    text-align: center;
}

.main_card_blog .card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 28.5845px;
    text-transform: capitalize;
}

.name_section_blog {
    margin: 25px 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main_card_blog .image_usr_blog {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 7px;
}

.main_card_blog .name_section_blog p {
    font-size: 12.7px;
    opacity: 0.7;
    display: flex;
    align-items: baseline;
    column-gap: 6.5px;
}

.brdr_lft {
    border-left: 1px solid rgba(164, 164, 164, 0.581);
    padding-left: 10px;
    margin-left: 10px;
}

.main_card_blog .card-text {
    font-size: 16px;
    opacity: 0.9;
    line-height: 24px;
}

.main_card_blog .br-12 {
    border-radius: 12px;
}