body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-Input-group-fields {
  width: 100%;
}

.profile-dropdown .dropdown-toggle img {
  height: 40px;
  width: auto;
  border-radius: 60px;
}

.navbar-brand {
  cursor: pointer;
}

.theme-link {
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
}

.theme-link:hover {
  color: var(--primary);
  text-decoration: underline;
}

.goldbadgetext a {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.goldbadgetext a:hover {
  color: var(--primary);
}

.ml-0 {
  margin-left: 0 !important;
}

.theme-forminput .form-label {
  text-align: left;
}

.view-only-form .form-control:disabled {
  background: transparent;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-wrapper ul {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
}

.pagination-wrapper ul li a,
.pagination-wrapper ul li span {
  display: block;
  padding: 8px;
  min-width: 30px;
  background: #fff;
  color: #333;
  text-align: center;
  text-decoration: none;
}

.pagination-wrapper ul li a:hover {
  background: #eee;
}

.pagination-wrapper ul li.selected a {
  background: var(--primary) !important;
  color: #fff !important;
}

button.non-btn {
  color: #333;
  background: transparent;
  border: 1px solid #333;
  padding: 5px 10px;
}

.verification-box {
  padding-inline: 15px !important;
}

.ManagementCertificate .no-found {
  color: #999;
  margin-bottom: 15px;
  display: block;
}

.upload-status p {
  font-size: 12px;
  color: #999;
}

.upload-status p.success {
  color: var(--primary);
}

.upload-status p.error {
  color: rgb(193, 42, 42);
}

.upload-status p.uploading span {
  width: 15px;
  height: 15px;
}

.upload-status p.uploading span i {
  width: 6px;
  height: 6px;
}

p.no-results {
  text-align: center;
  color: #999;
}

li.li_parent.editNotAllowed:hover {
  background: #fff !important;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}

li.li_parent.editNotAllowed:hover p {
  visibility: visible !important;
  z-index: 1 !important;
}

.themechecboxbar input[type="checkbox"] {
  padding: 10px;
}

.Faqs {
  padding-bottom: 100px !important;
}

.main-loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-spin .ant-spin-dot-item {
  background-color: var(--primary);
}

.video-call-area .loading-view {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.video-screen .room {
  display: flex;
  align-items: center;
  width: 100%;
}

.video-screen .room .local-participant {
  position: fixed;
  left: 15px;
  top: 15px;
}

.video-screen .room .local-participant,
.video-screen .room .local-participant video {
  height: 110px;
  max-height: 110px;
  width: 150px;
  max-width: 150px;
  background: #000;
}

.video-screen .room .remote-participants {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
}

.video-screen .room .remote-participants video {
  height: 100%;
  width: auto;
}

.video-screen .room .participant-box .participant {
  width: 100%;
}

.video-screen .room .participant-box h3 {
  display: none;
}

.video-toll-bar {
  display: flex;
  align-items: center;
  background: #fff;
  border-top: 1px solid #dedede;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
}

.video-toll-bar .actions {
  margin-right: 0;
  margin-left: auto;
}

.video-screen .room .participant-box:nth-child(odd) {
  background: #f1f1f1;
}

.video-screen .room .participant-box:nth-child(even) {
  background: #cccccc;
}

.video-screen .room .no-participants {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
}

.bold-title {
  font-weight: 500;
  text-transform: capitalize;
}

.appointment-status.scheduled {
  color: var(--primary);
}

.appointment-status.ongoing {
  color: var(--success);
}

.appointment-status.cancelled {
  color: var(--danger);
}

/* .appointment-status.completed{
  color: var(--primary);
} */

.theme-formselectsearch div[class$="-container"] {
  width: 100%;
}

.text-primary {
  color: var(--primary) !important;
  text-decoration: none;
}

a {
  cursor: pointer;
}

.inline-actions {
  display: flex;
  align-items: center;
}

.inline-actions a {
  margin: 0 2.5px;
}

.inline-actions.label {
  padding-top: 27px;
}

.sign-pad canvas {
  border: 1px solid #dedede;
}

.theme-formbtn {
  display: flex;
  align-items: center;
}

.sign-pad.center {
  text-align: center;
}

.theme-formbtn.center {
  text-align: center;
  justify-content: center;
}

.theme-formbtn .btn {
  min-width: 100px;
}

.right-nav-things .searchbar:focus,
.right-nav-things .searchbar:target {
  box-shadow: none !important;
  border: none !important;
}

:where(.css-dev-only-do-not-override-w8mnev).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary);
}

:where(.css-dev-only-do-not-override-w8mnev).ant-tabs .ant-tabs-ink-bar {
  background: var(--primary);
}

.list-links{
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-links li{
  display: block;
}

.list-links li a {
  color: var(--primary) !important;
  font-size: 12px;
  display: block;
  width: 100%;
  white-space: nowrap;
}

.ck-editor__editable_inline {
  min-height: 200px;
}