@font-face {
    font-family: Pangram;
    src: url("./Pangram-ExtraLight.otf") format("opentype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: Pangram;
    src: url("./Pangram-Light.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Pangram;
    src: url("./Pangram-Regular.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Pangram;
    src: url("./Pangram-Medium.otf") format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Pangram;
    src: url("./Pangram-Bold.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Pangram;
    src: url("./Pangram-ExtraBold.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Pangram;
    src: url("./Pangram-Black.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
}