@import url("./base/style.css");
@import url("./fonts/fonts.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Pangram";
}

/* Colors Variables */
:root {
  --primary: #1198DE;
  --primaryLight: rgba(17, 152, 222, 0.08);
  --danger: #cd1f1f;
  --success: #1fcd42;
  --primaryHover: #0b85c2;
  --textOnPrimary: #fff;
  --borderColor: #DDDDDD;
  --borderLightColor: #EFEFEF;
  --borderRadius: 5px;
  --borderRadiusMore: 25px;
  --textColor: #333;
  --textColorLight: #666;
  --textColorDark: #000;
  --boxShadow: rgba(0, 0, 0, 0.1) 0 0 5px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  padding-right: 0 !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  background-color: var(--white);
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a {
  color: var(--primary);
  text-decoration: underline;
}

a:hover {
  color: var(--secondry);
}

ol,
ul {
  padding-left: 0;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn-primary{
  background: var(--primary);
  border-color: var(--primary);
}

.btn {
  /* background: var(--primary);
  border-color: var(--primary); */
  border-radius: var(--borderRadius);
  font-size: 14px;
  padding: 10px 15px;
}

.form-control {
  padding: 12px 15px;
}

.form-control:hover,
.form-control:focus {
  outline: none;
  box-shadow: none;
}

.btn-primary:hover,
.btn-primary:focus {
  background: var(--primaryHover);
  border-color: var(--primaryHover);
}

.btn-outline-primary {
  background: transparent;
  border-color: var(--primary);
  color: var(--primary);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--textOnPrimary);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background: var(--primary);
  border-color: var(--primary);
}

.site-header-bar {
  background: var(--primary);
}

.site-header-bar p {
  margin: 0;
  color: var(--textOnPrimary);
  padding: 10px 0;
}

.site-header-bar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}

.site-header-bar ul li a {
  color: var(--textOnPrimary);
  padding: 0 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-header-bar ul li {
  padding: 0 10px;
}

.site-header-bar ul li a span:first-child {
  padding-right: 10px;
}

.site-header-bar ul li:last-child {
  padding-right: 0;
}

.navbar-menu-items {
  padding-left: 25px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-menu-items .nav-item a {
  padding: 25px 0;
  font-size: 15px;
  border-bottom: 1.5px solid transparent;
  color: #333;
  font-weight: 500;
  text-decoration: none;
  margin: 0 15px;
}

.navbar-menu-items .nav-item a:hover {
  border-color: var(--primary);
}

.navbar-menu-items .nav-item a.active {
  border-color: var(--primary);
}

.searchbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--borderColor);
  border-radius: var(--borderRadius);
}

.searchbar svg {
  margin: 0 10px;
  font-size: 16px;
}

.searchbar input {
  border: none;
  font-size: 14px;
  padding-left: 0;
  min-width: 200px;
}

.searchbar input:hover,
.searchbar input:focus {
  outline: none !important;
  border: none !important;
}

.right-nav-things {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-nav-things .searchbar {
  margin-right: 15px;
}

.navbar-brand img {
  height: 70px;
  width: auto;
}

.site-banner .right img {
  height: 300px;
  width: auto;
  margin-top: -60 px;
}

.site-banner .right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-banner {
  border-radius: var(--borderRadius);
  background: var(--primary);
  margin-top: 25px;
}

.site-banner .content {
  padding: 60px 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--textOnPrimary);
}

.section-pad {
  padding: 50px 0;
}

.section-title {
  padding-bottom: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section-title.left {
  align-items: flex-start;
  text-align: left;
}

.section-title p {
  color: var(--primary);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}

.section-title h1 {
  color: #333;
  font-weight: 600;
  font-size: 36px;
  padding-bottom: 10px;
  text-transform: uppercase;
  position: relative;
}

.section-title h1::after {
  content: "";
  position: absolute;
  width: 60%;
  background: var(--primary);
  height: 1.5px;
  bottom: 0;
  left: 20%;
}

.section-title.left h1::after {
  left: 0;
}

.step-box .img-box img {
  height: 100px;
  width: auto;
}

.step-box {
  text-align: center;
  width: 280px;
  margin: 15px auto;
}

.step-box .content-box {
  text-align: left;
}

.step-box .content-box .title {
  display: flex;
  align-items: center;
  padding: 10px 0;
  justify-content: center;
}

.step-box .content-box .title h1 {
  color: var(--primary);
  font-weight: 600;
  font-size: 32px;
  margin: 0;
  margin-right: 10px;
}

.step-box .content-box .title h2 {
  color: #333;
  font-size: 18px;
  margin: 0;
}

.step-box .content-box p {
  font-size: 14px;
}

.blog-box .img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: var(--borderRadius);
  overflow: hidden;
  min-height: 300px;
  margin: 15px 0;
}

.blog-box .img-box-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
}

.blog-box .img-box-actions a {
  color: #fff;
  font-size: 60px;
  cursor: pointer;
}

.blog-box .img-box-actions a:hover {
  color: #fff;
  font-size: 65px;
}

.blog-box .content-box {
  margin-top: 15px;
}

.blog-box .content-box h1 {
  font-size: 16px;
  font-weight: 600;
}

.blog-box .content-box p {
  font-size: 13px;
}

#about-us-content {
  background: url("./../../public/images/about-us.png");
  background-size: cover;
}

.about-us-box-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 550px;
}

.about-us-box {
  background: #fff;
  padding: 35px;
  width: 75%;
  margin-left: 0;
  margin-right: auto;
}

.about-us-box h3 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 12px;
  border: 1px solid var(--borderColor);
  display: inline-block;
  padding: 5px;
}

.about-us-box h1 {
  font-size: 28px;
  font-weight: 600;
}

.about-us-box p {
  margin: 15px 0;
}

.about-features {
  margin: 10px 0;
  margin-bottom: 25px;
}

.about-features ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.about-features ul li {
  display: flex;
  align-items: center;
}

.about-features ul li span.icon {
  border: 1px solid var(--primary);
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50px;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.about-features ul li span:not(.icon) {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
}

.doctor-box {
  border: 1px solid var(--borderLightColor);
  border-radius: 12px;
  padding: 15px;
  margin: 15px 0;
  cursor: pointer;
  display: block;
  color: #333;
  text-decoration: none;
}

.doctor-box:hover {
  border-color: var(--primary);
  box-shadow: 0px 0px 35.4608px rgba(0, 0, 0, 0.02), 0px 25.0311px 33.3748px -3.12889px rgba(3, 9, 50, 0.02);
}

.doctor-box .img-box {
  border-radius: var(--borderRadius);
  border: 1px solid var(--borderLightColor);
  height: 210px;
  width: 100%;
  background: #d6c9f4;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.doctor-box .content-box {
  margin: 10px 0;
}

.doctor-box h1 {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.doctor-box .title p {
  font-size: 12px;
  margin: 5px 0;
  color: #555;
}

.doctor-box .info {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.doctor-box .info span.price {
  font-weight: 600;
  font-size: 18px;
  margin-right: 5px;
}

.doctor-box .info span:not(.price) {
  font-size: 12px;
}

.slick-slider {
  display: flex;
  margin-bottom: 35px;
}

.slick-slider .doctor-box {
  margin: 10px;
}

.slick-slider .slick-list {
  display: block;
  width: calc(100% + 20px);
  flex: 0 0 calc(100% + 20px);
  margin: 0 -10px;
}

.slick-slider ul.slick-dots {
  order: 2;
  margin-left: 0;
  margin-right: auto;
  width: auto;
}

.slick-slider .slick-dots li {
  margin: 0 !important;
  width: auto !important;
  height: auto !important;
}

.slick-dots li button {
  width: 5px !important;
  height: 5px !important;
  background: #DDE0E4 !important;
  border-radius: 50px !important;
  margin-right: 10px !important;
  padding: 4px !important;
}

.slick-dots li button:before {
  display: none;
}

.slick-dots li.slick-active button {
  width: 35px !important;
  background: var(--primary) !important;
}

.slick-dots li.slick-active button::before {
  display: none;
}

.slick-slider .slick-arrow {
  width: 36px;
  height: 36px;
  border: 1px solid var(--primary);
  color: var(--primary);
  fill: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 16px;
  order: 4;
  position: absolute;
  right: 0;
  bottom: -45px;
  cursor: pointer;
  transition: all 0s;
}

.slick-slider .slick-arrow:hover {
  fill: var(--textOnPrimary);
  color: var(--textOnPrimary);
  background: var(--primary);
  transition: all 0s;
}

.slick-slider .slick-arrow.custom-prev-arrow {
  right: 45px;
}

.testimonial-box {
  border: 1px solid var(--borderLightColor);
  border-radius: var(--borderRadius);
  padding: 35px 25px;
  cursor: pointer;
  margin: 15px 0;
  display: block;
  color: #333;
  text-decoration: none;
}

.slick-slider .testimonial-box {
  margin: 10px;
}

.testimonial-box .review {
  font-size: 14px;
  color: #555;
  margin: 20px 0;
}

.testimonial-box .user-info {
  display: flex;
  align-items: center;
}

.testimonial-box .user-info .img {
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  border: 1px solid var(--borderLightColor);
  background-color: #eee;
  border-radius: 50px;
}

.testimonial-box .user-info h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
}

.testimonial-box .user-info p {
  font-size: 10px;
  margin: 0;
  line-height: 12px;
}

.section-title-2 h1 {
  border: none;
  text-transform: capitalize;
  color: var(--primary);
}

.section-title-2 h1::after {
  display: none;
}

.section-title-2 p {
  color: #333;
  text-transform: capitalize;
}

.accordion {
  border: none;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  border: none;
  border-bottom: 1px solid var(--borderLightColor);
}

.accordion-header .accordion-button {
  padding: 25px 0;
  box-shadow: none;
  font-size: 18px;
}

.accordion-header .accordion-button:hover,
.accordion-header .accordion-button:focus {
  box-shadow: none;
  outline: none;
}

.accordion-button:not(.collapsed) {
  background: transparent;
  color: #333;
}

.accordion-header .accordion-button::after {
  color: #333 !important;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.accordion-header .accordion-button span {
  margin-right: 15px;
  color: var(--primary);
}

.accordion-body {
  padding-top: 0;
  padding-left: 32px;
}

.site-footer-bar {
  background: var(--primary);
}

.site-footer-bar p {
  margin: 0;
  color: var(--textOnPrimary);
  padding: 10px 0;
}

.site-footer-bar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}

.site-footer-bar ul li a {
  color: var(--textOnPrimary);
  padding: 0 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-footer-bar ul li:last-child {
  padding-right: 0;
}

.site-footer-bar ul li {
  padding: 0 10px;
}

.site-footer-bar ul li a span:first-child {
  padding-right: 10px;
}

.site-footer-bar ul li a span:first-child {
  padding-right: 0;
}

.section-title-3 h1 {
  text-transform: capitalize;
}

.section-title-5 {
  padding-bottom: 15px;
}

.section-title-5 h1 {
  font-size: 24px;
  text-transform: capitalize;
}

.section-title-6 {
  font-size: 18px;
  margin: 10px 0;
}

.section-title-4 {
  padding-bottom: 15px;
}

.section-title-4 h1 {
  border: none;
  text-transform: capitalize;
  font-size: 25px;
}

.section-title-4 h1::after {
  display: none;
}


#about-title {
  z-index: 1;
  position: relative;
}

#about-sec-image {
  background-image: url("./../../public/images/doctors-about.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  z-index: 0;
  position: relative;
}


#about-stats {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

#about-stats-box {
  display: flex;
  justify-content: center;
  background: var(--primary);
}

#about-title h1 {
  font-size: 50px;
  background: #f7f7f7;
  padding: 40px;
  margin-bottom: -50px;
}

#about-title p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 15px;
}

#about-vision-box {
  background: #F4F0F8;
  padding: 65px 35px;
}

.about-vision-mission-box h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.about-vision-mission-box h2 {
  font-size: 14px;
}

.about-vision-mission-box p {
  margin-top: 20px;
  font-size: 13px;
  color: #444;
}

#about-stats-box ul {
  list-style: none;
  display: flex;
  padding: 0;
  width: 100%;
  margin: 0;
}

#about-stats-box ul li {
  width: 100%;
  text-align: center;
  padding: 35px 15px;
  color: #fff;
}

#about-stats-box ul li h1 {
  font-size: 50px;
  margin: 0;
}

.about-content-card .img-box {
  height: 360px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-content-card h1 {
  font-size: 24px;
  font-weight: 600;
}

.about-content-card h2 {
  font-size: 15px;
}

.about-content-card p {
  font-size: 13px;
  color: #444;
  margin-top: 10px;
  line-height: 25px;
}

.about-content-card .content-box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.about-content-card.right {
  text-align: right;
}

.about-content-card h1 {
  width: 100%;
}

.about-vision-mission-box {
  margin: 15px 0;
}

.about-content-card {
  padding: 15px;
}

.pagination-wrapper {
  margin: 15px 0;
}

.pagination-wrapper ul {
  list-style: none;
}

.pharmacy-box {
  border: 1px solid var(--borderLightColor);
  border-radius: 12px;
  padding: 15px;
  margin: 15px 0;
  cursor: pointer;
  display: block;
  color: #333;
  text-decoration: none;
}

.pharmacy-box:hover {
  border-color: var(--primary);
  box-shadow: 0px 0px 35.4608px rgba(0, 0, 0, 0.02), 0px 25.0311px 33.3748px -3.12889px rgba(3, 9, 50, 0.02);
}

.pharmacy-box .img-box {
  border-radius: var(--borderRadius);
  border: 1px solid var(--borderLightColor);
  height: 210px;
  width: 100%;
  background: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pharmacy-box .content-box {
  margin: 10px 0;
}

.pharmacy-box h1 {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.pharmacy-box .title p {
  font-size: 12px;
  margin: 5px 0;
  color: #555;
}

.pharmacy-box .info {
  display: flex;
  align-items: center;
}

.pharmacy-box .info span.price {
  font-weight: 600;
  font-size: 18px;
  margin-right: 5px;
}

.pharmacy-box .info span:not(.price) {
  font-size: 12px;
}

.pharmacy-box a {
  margin-top: 10px;
}

.pharmacy-box a,
.pharmacy-box a button {
  width: 100%;
  display: block;
}

.blog-box {
  margin: 15px 0;
}

.blog-box .img-box {
  border-radius: var(--borderRadius);
  border: 1px solid var(--borderLightColor);
  height: 100%;
  width: 100%;
  background: #eee;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}

.blog-box .content {
  padding: 10px 0;
}

.blog-box .content .title h1 {
  font-size: 22px;
  font-weight: 600;
}

.blog-box .content .title h1 a {
  color: #000;
  text-decoration: none;
}

.blog-box .content .title p {
  font-size: 13px;
  font-weight: 600;
}

.blog-box .content .info {
  margin-top: 15px;
  font-size: 13px;
  line-height: 25px;
}

.blog-box .info a {
  text-decoration: none;
  margin-left: 10px;
}

.auth-layout {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-box .banner {
  height: 100%;
  border-right: 1px solid var(--borderLightColor);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-box .banner img {
  height: 200px;
  width: auto;
}

.auth-form {
  width: 80%;
  margin: 30px auto;
}

.form-control,
input {
  border: 1px solid var(--borderColor);
}

.auth-box .formtitlebar h4 {
  font-weight: 600;
  font-size: 32px;
  margin: 0;
}

.auth-box .formtitlebar p {
  font-size: 16px;
  color: #666;
}

.auth-box .formtitlebar {
  margin-bottom: 30px;
}

.theme-forminput {
  margin-bottom: 15px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-Input-group-fields {
  width: 100%;
}

.profile-dropdown .dropdown-toggle img {
  height: 40px;
  width: auto;
  border-radius: 60px;
}

.navbar-brand {
  cursor: pointer;
}

.theme-link {
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
}

.theme-link:hover {
  color: var(--primary);
  text-decoration: underline;
}

.goldbadgetext a {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.goldbadgetext a:hover {
  color: var(--primary);
}

.ml-0 {
  margin-left: 0 !important;
}

.theme-forminput .form-label {
  text-align: left;
}

.view-only-form .form-control:disabled {
  background: transparent;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-wrapper ul {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
}

.pagination-wrapper ul li a,
.pagination-wrapper ul li span {
  display: block;
  padding: 8px;
  min-width: 30px;
  background: #fff;
  color: #333;
  text-align: center;
  text-decoration: none;
}

.pagination-wrapper ul li a:hover {
  background: #eee;
}

.pagination-wrapper ul li.selected a {
  background: var(--primary) !important;
  color: #fff !important;
}

button.non-btn {
  color: #333;
  background: transparent;
  border: 1px solid #333;
  padding: 5px 10px;
}

.verification-box {
  padding-inline: 15px !important;
}

.ManagementCertificate .no-found {
  color: #999;
  margin-bottom: 15px;
  display: block;
}

.upload-status p {
  font-size: 12px;
  color: #999;
}

.upload-status p.success {
  color: var(--primary);
}

.upload-status p.error {
  color: rgb(193, 42, 42);
}

.upload-status p.uploading span {
  width: 15px;
  height: 15px;
}

.upload-status p.uploading span i {
  width: 6px;
  height: 6px;
}

p.no-results {
  text-align: center;
  color: #999;
}

li.li_parent.editNotAllowed:hover {
  background: #fff !important;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}

li.li_parent.editNotAllowed:hover p {
  visibility: visible !important;
  z-index: 1 !important;
}

.themechecboxbar input[type="checkbox"] {
  padding: 8px;
}

.Faqs {
  padding-bottom: 100px !important;
}

.main-loader {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-spin .ant-spin-dot-item {
  background-color: var(--primary);
}

div[class^="ReactFlagsSelect-module_flagsSelect"] {
  padding-bottom: 0;
}

div[class^="ReactFlagsSelect-module_flagsSelect"] button {
  padding-top: 12px;
  padding-bottom: 8px;
  border: 1px solid var(--border);
}

.search_filterbar .filtericon img {
  width: 20px;
}

.theme-forminput label,
.theme-forminput .form-label {
  color: #555555;
  font-size: 13px;
}

.auth-form .theme-formbtn {
  width: 100%;
  display: block;
}

.auth-form .theme-formbtn a,
.auth-form .theme-formbtn a button,
.auth-form .theme-formbtn button {
  width: 100%;
}

.formbtngroups h6 {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 15px;
}

.formbtngroups h6 a {
  margin-left: 5px;
  text-decoration: none;
  color: var(--primary);
  font-weight: 600;
}

.orbar p {
  font-size: 15px;
  color: #555;
}

#doctor-info-box {
  display: flex;
}

#doctor-info-box .doctor-info {
  width: 350px;
  padding-right: 30px;
}

#doctor-info-box .doctor-info .img-box {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
  height: 250px;
  width: 100%;
  border-radius: var(--borderRadius);
}

#doctor-info-box .doctor-info .title {
  margin-top: 15px;
}

#doctor-info-box .doctor-info .title h1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

#doctor-info-box .doctor-info .title h2 {
  font-size: 12px;
  color: #555;
}

#doctor-info-box .doctor-details {
  width: 100%;
  padding-top: 15px;
}

.info-card {
  margin-bottom: 15px;
}

.info-card h3 {
  color: #555;
  font-size: 14px;
}

.info-card p {
  font-size: 14px;
  line-height: 25px;
}

.info-card p.price {
  font-size: 16px;
  font-weight: 600;
}

.info-card.with-actions {
  display: flex;
  align-items: center;
  width: 100%;
}

.info-card.with-actions .button-box {
  margin-right: 0;
  margin-left: auto;
}

.testimonial-box .user-info .rating {
  margin-right: 0;
  margin-left: auto;
}

.testimonial-box .user-info .rating h5 {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textColor);
}

.testimonial-box .user-info .rating h5 svg {
  color: var(--primary);
  margin-top: -5px;
  margin-left: 5px;
}

.theme-forminput-radios .input-group {
  display: flex;
  align-items: center;
}

.theme-forminput-radios .input-group .theme-forminput-radio-item {
  width: auto;
}

.radio-bridge .form-check-inline .form-check-input {
  width: 18px;
  height: 18px;
}

.radio-bridge .form-check-inline .form-check-input:hover,
.radio-bridge .form-check-inline .form-check-input:focus {
  outline: none;
  box-shadow: none;
}

.radio-bridge .form-check-inline label {
  cursor: pointer;
}

.page-title-bar {
  background: var(--primaryLight);
}

.page-title-bar .content {
  padding: 65px 0;
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
}

.page-title-bar .content h1 {
  font-size: 35px;
  font-weight: 600;
  margin: 10px 0;
}

.page-title-bar .content h2 {
  font-size: 14px;
  font-weight: 400;
  color: var(--textColorLight);
  margin: 0;
}

.page-title-bar .content h6 {
  font-size: 12px;
  margin: 0;
  margin-bottom: -5px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 800;
}

.cms-content h1 {
  font-size: 26px;
  margin: 10px 0;
  font-weight: 600;
}

.cms-content p {
  line-height: 22px;
}

.btn-full {
  width: 100%;
}

.theme-tabs {
  width: 100%;
}

.theme-tabs ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.theme-tabs li a {
  display: block;
}

.theme-tabs li a {
  background: #F4F4F4;
  color: var(--textColor);
  text-decoration: none;
  padding: 12px 15px;
  font-weight: 500;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
  cursor: pointer;
}

.theme-tabs li a:hover {
  background: var(--primaryLight);
}

.theme-tabs li a.active {
  background: var(--primary);
  color: var(--textOnPrimary);
}

.theme-tabs li:first-child a {
  border-top-left-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
}

.theme-tabs li:last-child a {
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
}

.doctor-box-2 {
  display: flex;
  align-items: center;
}

.doctor-box-2 .img-box {
  margin-right: 10px;
  height: 75px;
  min-height: 75px;
  width: 75px;
  min-width: 75px;
}

.doctor-box-2 h1 {
  margin-top: 10px;
}

.no-pad-no-border {
  padding: 0;
  border: none;
}

.grid-table-body {
  border: 1px solid var(--borderLightColor);
  border-radius: var(--borderRadius);
}

.grid-table-primary .grid-table-body {
  border-color: var(--primary);
}

.grid-table .row {
  height: 100%;
}

.grid-table-head-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.grid-table-data-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grid-table-data-column p {
  font-size: 14px;
}

.grid-table-body .grid-table-item:not(:first-child) .grid-table-row {
  border-top: 1px solid var(--borderLightColor);
}

.grid-table-head-column h6 {
  color: var(--textColorLight);
  font-size: 12px;
}

.data-n-actions {
  display: flex;
  align-items: center;
}

.data-n-actions a,
.data-n-actions button {
  margin-left: auto;
  margin-right: 0;
}

#user-dropdown-toggle {
  background: transparent;
  padding: 0;
  border: none;
}

#user-dropdown-toggle::after {
  display: none;
}

#user-dropdown-toggle .img-box {
  height: 43px;
  width: 43px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eee;
  border: 1px solid var(--borderLightColor);
  border-radius: 50px;
}

.dropdown-menu {
  right: 0;
  left: auto !important;
  border-color: var(--borderLightColor);
}

.dropdown-menu a {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 7.5px 10px;
  padding-bottom: 8.5px;
}

.dropdown-menu a:hover {
  background: var(--primaryLight);
}

.dropdown-menu a svg {
  margin-top: -2px;
  margin-right: 5px;
  color: var(--primary);
}

.big-img-box {
  margin: 15px 0;
}

.big-img-box.center {
  text-align: center;
}

.big-img-box p span {
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}

.big-img-box.center .img-box {
  margin: 0 auto;
}

.big-img-box .img-box {
  height: 150px;
  width: 150px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-color: #eee;
}

.big-img-box .img-box a {
  background: #1198DE;
  color: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  bottom: 10px;
}

.big-img-box .img-box a svg {
  color: #fff;
  font-size: 18px;
  fill: #fff;
}

.profile-info-box {
  border-radius: 10.069px;
  border: 1px solid var(--borderLightColor);
  background: #FFF;
  padding: 15px;
  box-shadow: 0px 21.00288963317871px 28.003856658935547px -2.625361204147339px rgba(3, 9, 50, 0.02), 0px 0px 29.75409507751465px 0px rgba(0, 0, 0, 0.02);
}

.user-list-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-list-info ul li:not(:first-child) {
  border-top: 1px solid var(--borderLightColor);
}

.user-list-info ul li {
  text-align: center;
  padding: 15px 0;
}

.user-list-info li h2 {
  font-size: 22px;
}

.user-list-info li p {
  font-size: 12px;
}

.user-list-info li h5 {
  font-size: 14px;
}

.nav-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-toggler .user-dropdown {
  margin-right: 15px;
  margin-left: 0 !important;
}

.authblanklayout .singlelogo {
  text-align: center;
  margin: 15px 0;
  padding-bottom: 15px;
}

.authblanklayout img {
  height: 150px;
  width: auto;
}

.authblanklayout .content-area {
  padding: 35px;
  border-radius: var(--borderRadiusMore);
  border: 1px solid var(--borderLightColor);
  margin: 0 15px;
}

.sidebar-panel {
  background: rgba(17, 152, 222, 0.02);
}

.sidebarhead ul li a {
  font-weight: 500;
}

.sidebarhead ul li a.active {
  background: var(--primary);
  border-left-color: var(--textOnPrimary);
  color: var(--textOnPrimary);
}

.card_dashboard h1 {
  letter-spacing: normal;
  font-size: 24px;
  display: flex;
  width: 100%;
}

.card_dashboard h1 span.growth {
  font-size: 13px;
  margin-left: auto;
  margin-right: 0;
  margin-top: auto;
  margin-bottom: 0;
  font-weight: 400;
}

.card_dashboard h1 span.growth.up {
  color: #0CAF60;
}

.card_dashboard h1 span.growth.down {
  color: #F84545;
}

.card_dashboard {
  align-items: center;
}

.card_dashboard .icon {
  background: var(--borderLightColor);
  color: var(--primary);
  min-width: 35px;
  width: 35px;
  min-height: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 10px;
}

.card_dashboard .text_data {
  width: 100%;
}

.suppliers_bar {
  padding: 25px;
}

.suppliers_bar p {
  font-size: 14px;
}

.suppliers_bar .row {
  padding: 8px 0 !important;
}

.suppliers_bar .progress,
.suppliers_bar .progress-bar {
  height: 10px;
}

.graph-card-titles {
  font-family: inherit;
}

.graph-card-actions {
  min-height: 40px;
}

.graph-card-actions .theme-forminput {
  margin: 0;
}

.img-selector {
  border: 2px dashed #1198DE;
  background: #FAFDFE;
  border-radius: var(--borderRadius);
  padding: 25px;
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.img-selector p {
  font-size: 12px;
  color: var(--textColorLight);
  margin: 0;
}

.img-selector h6 {
  margin: 5px 0;
  color: var(--primary);
  font-size: 15px;
}

.img-selector h6 svg {
  font-size: 15px;
  opacity: 1;
}

.img-selector svg {
  color: var(--primary);
  font-size: 25px;
  opacity: 0.5;
}

hr {
  border-color: var(--borderLightColor);
  opacity: 1;
}

.table-actions {
  display: flex;
  align-items: center;
}

.table-actions a {
  margin: 0 5px;
  color: var(--primary) !important;
}

.table-actions a svg {
  font-size: 16px;
}

.table-actions a.danger {
  color: var(--danger) !important;
  font-size: 18px;
}

.theme-table thead {
  background: transparent !important;
}

.theme-table th {
  font-size: 12px;
  color: var(--textColorLight);
  padding: 10px 20px !important;
  background: transparent !important;
}

.theme-table tbody {
  background: transparent !important;
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadius);
}

.theme-table tbody td {
  vertical-align: middle;
}

.table-head-options {
  display: flex;
  align-items: center;
  width: 100%;
}

.table-head-options .right {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
}

.table-doctor-box .doctor-box {
  margin: 0;
}

.table-doctor-box .doctor-box .title p {
  display: none;
}

.auth-form .img-area img {
  height: 100px;
  margin-bottom: 30px;
}

.auth-user-box .banner {
  background: url("./../../public/images/auth-bg-doctor.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
}

.auth-user-layout .auth-area {
  margin-left: -50px;
  background: #fff;
  min-height: 100vh;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-list-info-2-1 ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.user-list-info-2-1 ul li {
  border-top: 1px solid var(--borderLightColor);
}

.user-profile-name {
  display: flex;
  align-items: center;
}

.user-profile-name .img {
  height: 30px;
  width: 30px;
  background-size: cover;
  background-position: center;
  border: 1px solid #fefefe;
  background-color: #eee;
  border-radius: 50px;
  margin-right: 10px;
}

.user-profile-name h4 {
  font-size: 16px;
  margin: 0;
}

.user-profile-name svg {
  margin-top: 3px !important;
}

ul li.divider {
  margin: 10px 0;
  height: 1px;
  background: #dedede;
}

.img-selector-clicker {
  cursor: pointer;
}

.item-actions {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.item-actions a {
  width: 30px;
  height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid #DDD;
  background: #FFF;
  box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.04);
  margin-left: 7px;
}

.item-actions a.danger {
  color: var(--danger);
  font-size: 16px;
}

.item-actions a:hover {
  background: var(--primary);
  color: #fff;
}

.testimonial-box {
  position: relative;
}

.blog-box {
  position: relative;
}

.blog-box .item-actions {
  left: 15px;
  right: auto;
}

.theme-toast {
  z-index: 999999 !important;
}

.PhoneInput,
.PhoneInput input {
  height: 41.1px !important;
}

.appointment-slots ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline;
}

.appointment-slots ul li{
  display: inline-block;
}

.appointment-slots ul li a {
  display: block;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 0;
  margin: 4px;
  color: #333;
  text-decoration: none; 
  text-align: center;
  border-radius: var(--borderRadius);
  min-width: 100px;
  font-size: 15px;
  cursor: pointer;
}

.appointment-slots ul li a:not(.disabled):not(.active):hover {
  border-color: var(--primary);
  color: var(--primary);
}

.appointment-slots ul li a.active{
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.appointment-slots ul li a.disabled {
  border-color: #EFEFEF;
  background: #F2F2F2;
}

.fc-scrollgrid-sync-inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rwdp-DayCircle-today{
  display: none;
}

.rwdpDayBoxDesktop .rwdp-DayCircle-container{
  margin-top: 10px !important;
}

.rwdpDayBoxDesktop .rwdp-DayCircle{
  border-radius: var(--borderRadius) !important;
  border-color: var(--borderColor) !important;
  color: #333 !important;
  border-width: 1px !important;
  display: flex !important;
  flex-direction: column-reverse !important;
}

.rwdpDayBoxDesktop .rwdp-DayCircle-selected{
  background: transparent !important;
  border-color: var(--primary) !important;
  border-width: 2px !important;
}

.rwdpDayBoxDesktop .rwdp-DayCircle strong{
  margin-top: 5px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.rwdpNextWeekArrow, .rwdpPrevWeekArrow {
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  top: calc(50% - 15px) !important;
  border-right: 10px solid #ccc !important;
}

.rwdpNextWeekArrow{
  border-left: 10px solid #ccc !important;
  border-right: 0 !important;
}

.prescription-preview-box{
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
  margin: 15px 0;
}

.video-screen {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.video-screen .appointment-prescription-area {
  flex: 0 0 50%;
  min-height: 90vh;
  max-height: 90vh;
  overflow: auto;
  border-left: 1px solid #ddd;
  padding: 15px;
  padding-bottom: 100px;
}

iframe#prescription-pdf-iframe {
  width: 100%;
  height: auto;
  border: none;
  min-height: 60vh;
}

.no-join-error p{
  font-size: 10.5px;
  color: #ffc107;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}

.data-n-action-btns {
  display: flex;
  gap: 10px;
}

.data-n-actions.data-n-actions-b {
  gap: 5px;
}
