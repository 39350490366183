@media only screen and (max-width: 767px) {
    .right-nav-things {
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    .right-nav-things .searchbar {
        margin: 10px 0;
        width: 100%;
    }

    .right-nav-things a,
    .right-nav-things a button {
        display: block;
        width: 100%;
    }

    .site-banner {
        margin-top: 0;
    }

    .navbar .navbar-collapse {
        background: #fff;
        position: absolute;
        width: 100%;
        top: 100px;
        left: 0;
        box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
        z-index: 99;
    }

    .navbar-menu-items .nav-item a {
        width: 100%;
        display: block;
        padding: 15px 0;
        font-size: 15px;
        margin: 0;
    }

    .navbar-menu-items {
        padding: 0 25px;
    }

    .site-header-bar p,
    .site-footer-bar p {
        text-align: center;
        padding-bottom: 0;
    }

    .site-header-bar ul li:last-child,
    .site-footer-bar ul li:last-child {
        padding-right: 10px;
    }

    .site-header-bar ul,
    .site-footer-bar ul {
        justify-content: center;
    }

    .site-footer-bar ul li a {
        font-size: 14px;
        text-align: center;
        justify-content: center;
    }

    .step-box {
        width: 85%;
    }

    .section-pad {
        padding: 30px 0;
    }

    .about-us-box {
        margin-left: 5%;
        width: 90%;
    }

    #about-us-content {
        background-position: center;
    }

    .site-banner .content {
        padding: 35px;
    }

    #about-title h1 {
        margin-bottom: 0;
        padding: 25px;
        font-size: 30px;
    }

    #about-title p {
        padding: 25px;
    }

    #about-stats-box ul li h1 {
        font-size: 30px;
    }

    .about-content-card.right {
        text-align: left;
    }

    .about-content-card.right .row {
        flex-direction: column-reverse;
    }

    .about-content-card .img-box {
        margin-bottom: 15px;
    }

    .blog-box .img-box {
        height: 300px;
    }

    .head_nav {
        padding: 0 15px;
    }

    .auth-box .banner img {
        height: 150px;
        margin: 35px 0;
    }

    .auth-form {
        width: 100%;
        padding: 0 15px;
    }

    #doctor-info-box {
        flex-direction: column;
    }

    #doctor-info-box .doctor-info .img-box {
        max-width: 200px;
    }

    .info-card.with-actions {
        flex-direction: column;
        align-items: flex-start;
    }

    .info-card.with-actions .button-box {
        margin-top: 15px;
        margin-right: auto;
        margin-left: 0;
    }

    .page-title-bar .content {
        max-width: 100%;
        padding: 45px 0;
    }

    .page-title-bar .content h1 {
        font-size: 25px;
    }

    .grid-table-head {
        display: none;
    }

    .grid-table-body {
        border: none;
    }

    .grid-table-row {
        border: 1px solid var(--borderColor);
        padding: 15px;
        border-radius: var(--borderRadius);
        margin: 10px 0;
    }

    .grid-table-primary .grid-table-row {
        border-color: var(--primary);
    }

    .grid-table .row {
        height: auto;
    }

    .grid-table-data-column {
        padding: 5px 10px;
    }

    .grid-table .doctor-box-2 {
        margin: 0;
    }

    .dashboardsidebar {
        position: fixed;
        top: 0;
        min-height: 100vh;
        left: -100%;
        transition: all 0.3s;
        z-index: 3;
        background: #fff;
    }

    body.sidebar-open .dashboardsidebar {
        left: 0;
    }

    .mainoutlet {
        margin-left: 0;
    }

    .dashboard_layout {
        padding: 25px;
    }

    .stats-card {
        margin-bottom: 15px;
    }

    .auth-user-box .banner {
        display: none;
    }

    .auth-user-layout .auth-area {
        margin-left: 0;
    }

    .table-head-options {
        flex-direction: column;
        align-items: unset;
    }

    .table-head-options .right {
        flex-direction: column;
        align-items: unset;
        margin: auto;
        width: 100%;
    }

    .table-head-options .right button {
        margin-top: 10px;
        width: 100%;
        margin-left: 0 !important;
    }

    .main_dashboard_block .top_navbar {
        display: block;
    }

    .dashboard_wrapper {
        padding-top: 100px;
    }

    .main_dashboard_block .navbar {
        z-index: 2;
        padding: 10px 30px;
    }

    .inline-actions,
    .inline-actions.label {
        padding-top: 10px;
    }

    .site-header-bar ul,
    .site-footer-bar ul {
        display: block;
    }

    .site-header-bar ul li:first-child {
        margin-bottom: 10px;
    }

    .step-box .img-box img {
        height: 200px;
    }

    .section-title h1 {
        font-size: 20px;
    }

    .section-title {
        padding-bottom: 20px;
    }

    .rwdpDayBoxDesktop {
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -15px;
    }

    .rwdpDayBoxDesktop .rwdp-DayCircle-unavailable-text {
        top: 0;  
        font-size: 10px;
    }

    .rwdpDayBoxDesktop .rwdp-DayCircle-container {
        margin: 4px;
    }

    .rwdpDayBoxDesktop .rwdp-DayCircle {
        width: 65px;
        height: 80px;
        font-size: 12px;
    }

    .rwdpDayBoxDesktop .rwdp-DayCircle strong {
        margin-top: 0 !important;
    }

    .main_dashboard_block .top_navbar nav {
        background: #fff;
        border-bottom: 1px solid #eee;
    }

}


@media only screen and (min-width: 576px) {
    .nav-toggler .user-dropdown {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .right-nav-things .user-dropdown {
        display: none;
    }
}